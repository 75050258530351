import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class Checkbox extends React.Component {
  state = {
    values: [],
  };

  onChange = ({ target }) => {
    const { disabled, field, onChange } = this.props;

    if (disabled) {
      return;
    }

    const { name, value } = target;

    this.setState(
      prevState => {
        const { values } = prevState;
        const index = _.indexOf(values, value);
        if (index === -1) {
          values.push(value);
        } else {
          values.splice(index, 1);
        }

        return { values };
      },
      () => {
        onChange(this.state.values.join(','), name, field);
      },
    );
  };

  componentWillReceiveProps(nextProps){
    if(nextProps.checkedItems){
      let values = nextProps.checkedItems.split(',');
      this.setState({values});
    }
  }

  render() {
    const { disabled, field, values,checkedItems = '' } = this.props;

    let checkedItemsArray = checkedItems.split(',');

    return (
      <div>
        {values.map((item, key) => (
          <div key={key}>
            <label>
              <input
                type="checkbox"
                value={item}
                name={field.name}
                disabled={disabled}
                onChange={this.onChange}
                onClick={this.props.onClick}
                checked={(checkedItemsArray.indexOf(item) !== -1)?true:false}
              />
              <span style={{ marginLeft: 5 }}>{item}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  disabled: false,
  onChange: () => false,
  onClick: () => false,
};

export default Checkbox;
