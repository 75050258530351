import React from 'react';
import Flex, { Col } from '../../Flex';
//import Select2 from '../Select2';
// import Input from './../Input';

import GridTextInput from './GridTextInput';

class GridText extends React.Component {
  constructor(props) {
    super(props);
    this.state = this._parseProps(props);
  }

  _parseProps(p){
    const [cols, rows] = p.params
      .split('|')
      .map(i => i.split(','));
    let values = [];

    if(p.value){
      try{
        values = JSON.parse(p.value);
      }catch(e){
        if(rows && rows.length && cols && cols.length){
          for (let i = 0; i < rows.length; i++) {
            values[i] = [];
            for(let j = 0; j < cols.length; j++){
              values[i][j] = "";
            }
          }
        }
      }
    }else{
      if(rows && rows.length && cols && cols.length){
        for (let i = 0; i < rows.length; i++) {
          values[i] = [];
          for(let j = 0; j < cols.length; j++){
            values[i][j] = "";
          }
        }
      }
    }

    return {
      rows,
      cols,
      values
    };
  }

  componentWillReceiveProps(nextProps){
    if(typeof this.props.value !== 'undefined' && typeof nextProps.value !== 'undefined' && this.props.value !== nextProps.value){
      this.setState(this._parseProps(nextProps));
    }
    
    if(typeof this.props.params !== 'undefined' && typeof nextProps.params !== 'undefined' && this.props.params !== nextProps.params){
      this.setState(this._parseProps(nextProps));
    }
  }

  onChange = (value, name) => {
    const [, i, j] = name.split('-');
    const { values } = this.state;

    values[i][j] = value;

    this.setState(
      {
        values,
      },
      () => {
        this.props.onChange(JSON.stringify(values), this.props.field.name);
      },
    );
  };

  extractValues = (i,j) => {
    let {values} = this.state;
    if(values && values[i] && values[i][j]){
      return values[i][j]
    }else{
      return "";
    }
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.value !== nextProps.value){
      return true;
    }
    
    if(this.props.params !== nextProps.params){
      return true;
    }
    
    if(this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name){
      return true;
    }

    return false;
  }

  render() {
    const { rows, cols, /*values*/ } = this.state;

    // console.log("Grid Text rendered ", this.props.field);

    const padding = {
      left: 10,
      right: 10,
      bottom: 10,
      top: 10,
    };

    return (
      <Flex xs={{ align: 'center' }}>
        <Col xs={{ size: 12 }}>
          <Flex xs={{ align: 'center' }}>
              <Col xs={{ size: 2, ...padding }}>
              &nbsp;
              </Col>
            {cols && cols.map((col, key) => (
              <Col xs={{ size: 10 / (cols.length), ...padding }} key={key}>
                {col}
              </Col>
            ))}
          </Flex>
          {rows && rows.map((row, rowKey) => (
            <Flex xs={{ align: 'center' }} key={rowKey}>
              <Col xs={{ size: 2, ...padding }}>
              {row}
              </Col>
              {cols.map((col, colKey) => (
                <Col xs={{ size: 10 / cols.length, ...padding }} key={colKey}>
                  <GridTextInput 
                    fieldName={this.props.field.name} 
                    rowKey={rowKey} 
                    colKey={colKey} 
                    value={this.extractValues(rowKey,colKey)} 
                    onChange={this.onChange.bind(this)}
                  />
                 </Col>
              ))}
            </Flex>
          ))}
        </Col>
      </Flex>
    );
  }
}

export default GridText;
