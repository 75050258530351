import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import Icon from 'react-fa';
import { Col } from '../../Flex';

import cs from './component.pcss';

const HelpText = props => {

//   console.log("HelpText being rendered..");
  return (
    <React.Fragment>
        {props.helpText && (
            <Col xs={0.5}>
              <span className={cs.popoverTarget}>
                <Icon name="info-circle" data-tip={props.helpText} />
              </span>
            </Col>
        )}
        <ReactTooltip effect="solid" />
    </React.Fragment>
  )
};

HelpText.propTypes = {
  helpText: PropTypes.string,
};

HelpText.defaultProps = {
  helpText: null,
};

export default memo(HelpText);
