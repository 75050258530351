import React, { memo } from 'react';
import PropTypes from 'prop-types';

// import Icon from 'react-fa';
import Flex, { Col } from '../../Flex';
import Text from '../../Text';
// import { withImageUrl } from '../../../utils/withStaticUrl';
import classNames from 'classnames';
import cs from './component.pcss';

import HelpText from "./HelpText";

const getColCount = props => {
  let totalCount = 12;
  if (props) {
    if (props.helpText && !props.showHelpAsTitle) {
      totalCount=11.5;
    }
  }

  return totalCount;
}

const Group = props => {

  //console.log("Group being rendered..");
  return (
    <div className={classNames(cs.root)} style={{width:props.width}}>
      <Flex>
        <Col className={cs.label}>{props.label} {props.afterLabelContent}</Col>
        {props.required && (
          <Col xs={{ left: 5 }}>
            <Text fontSize={20} color="red">
              *
          </Text>
          </Col>
        )}
        {/* {props.helpFile && (
          <Col xs={{ left: 5 }}>
            <a style={{ color: 'grey' }} target="_blank" rel="noopener noreferrer" href={withImageUrl(props.helpFile)}>
              <Icon name="external-link" />
            </a>
          </Col>
        )} */}
      </Flex>
      {props.showHelpAsTitle && (
        <div style={{padding: '0px 0px 10px 0px',fontSize: 12}}>
          {props.helpText}
        </div>
      )}
      <div>
        <Flex xs={{ align: 'center' }}>
          <Col xs={getColCount(props)}>
            {props.children}
          </Col>
          { (typeof props.showHelpAsTitle === 'undefined' || !props.showHelpAsTitle) && (
            <HelpText helpText={props.helpText} />
          )}
        </Flex>
      </div>
    </div>
  )
};

Group.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  helpText: PropTypes.string,
  helpFile: PropTypes.string,
  showHelpAsTitle: PropTypes.bool,
  required: PropTypes.bool,
  width:PropTypes.string
};

Group.defaultProps = {
  helpText: null,
  helpFile: null,
  required: false,
  showHelpAsTitle: false
};

export default memo(Group);
