import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import createAction from '../../../utils/createAction';

import { Button, Input, ButtonSplit } from './../../../components/Form';
import internalApi from './../../../utils/internalApi';
import storage from './../../../utils/storage';
import toast from './../../../utils/toast';
import basicReducer from './../../../reducers/basicReducer';
import { CLEAR_TOKEN } from '../../../actions/auth';
import Footer from './../Footer';
import logo from './../../../assets/images/logo.png';
// import pandemicLogo from './../../../assets/images/pandemic-logo.png';
import pandemicLogo from './../../../assets/images/pandemic-logo.svg';

const Stats = props => {
    let history = useHistory();
    let rootDispatch = useDispatch();
    const [state, setState] = useReducer(basicReducer, {
        // planName: '',
        search: {
            name: '',
            email: '',
            company: '',
            phone: '',
            city: '',
            state: '',
            createdAt: ''
        },
        page:1,
        orderBy: 'createdAt',
        sortBy: 'DESC',
        users: [],
        usersCnt: 0,
        plansCnt: 0
    });

    const viewNextPage = () => {
        let nextPage = state?.page+1;
        if(state?.users?.length){
            setState({page:nextPage});
            listUsers({page:nextPage});
        }
    }

    const suspendCompany = (companyId) => {
        internalApi.post('company/suspend/'+companyId)
            .then(res => {
                toast("Company Suspended");
                listUsers();
                // console.log('res-> ', res);
            });
    }
    
    const enableCompany = (companyId) => {
        internalApi.post('company/enable/'+companyId)
            .then(res => {
                toast("Company Enabled");
                listUsers();
                // console.log('res-> ', res);
            });
    }
    
    const viewPrevPage = () => {
        let nextPage = state?.page-1;
        if(nextPage > 0){
            setState({page:nextPage});
            listUsers({page:nextPage});
        }
    }

    const setOrderBy = type => () => {
        let sortBy = state?.sortBy === 'DESC' ? 'ASC' : 'DESC';
        setState({ orderBy: type, sortBy });
        listUsers({orderBy:type, sortBy});
    }

    const updateSearch = type => value => {
        let search = state?.search;
        search[type] = value;
        setState({ search: { ...search },page:1 });
    };

    const logout = () => {
        storage.remove('token');
        rootDispatch(createAction(CLEAR_TOKEN, { token: null }));
        history.push('/');
    }

    const viewStats = () => {
        internalApi.get('stats/totalUsers')
            .then(res => {
                // console.log('res-> ', res);
                if (res?.payload?.item) {
                    setState({ ...res?.payload?.item });
                }
            });
    }

    const testEmail = () => {
        let email = window.prompt("Enter Email");
        if(email){
            internalApi.post('stats/sendTestEmail',{email})
            .then(res => {
                // console.log('res-> ', res);
                try {
                    if(res){
                        alert(JSON.stringify(res));
                    }
                }catch(e){
                    console.log(e);
                }
            });
        }
    }

    const runSearch = () => {
        listUsers();
    }

    const listUsers = (props) => {
        let {orderBy, sortBy,page} = props || {};

        if (!orderBy) {
            orderBy = state?.orderBy;
        }

        if (!sortBy) {
            sortBy = state?.sortBy;
        }
        
        if (!page) {
            page = state?.page;
        }

        internalApi.get('user/list', {
            params: {
                search: state?.search,
                orderBy,
                sortBy,
                page
            }
        })
            .then(res => {
                // console.log('res-> ', res?.payload?.items?.records);
                if (res?.payload?.items?.records) {
                    setState({ users: res?.payload?.items?.records });
                }
            });
    }

    useEffect(() => {
        viewStats();
        listUsers();
    },[]);

    return (
        <React.Fragment>

            <div id="wrapper" style={{ height: '100vh' }}>


                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">

                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                            <img src={logo} height="40" alt="Continuity Innovations" />

                            <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                                <img src={pandemicLogo} alt="" /> Admin
                            </div>
                            {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form> */}

                            <ul className="navbar-nav ml-auto">

                                <li className="nav-item dropdown no-arrow d-sm-none">
                                    <Button className="nav-link dropdown-toggle" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-search fa-fw"></i>
                                    </Button>
                                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                                        <form className="form-inline mr-auto w-100 navbar-search">
                                            <div className="input-group">
                                                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fas fa-search fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </li>

                                <li className="nav-item dropdown no-arrow">
                                    <Button style={{ cursor: 'pointer' }} onClick={logout} className="nav-link">
                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small"> <i className="fas fa-power-off"></i> Logout</span>
                                        {/* <img alt="" className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" /> */}
                                    </Button>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">

                                        <Button className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Logout
                                        </Button>
                                    </div>
                                </li>

                            </ul>

                        </nav>

                        <div className="container-fluid">

                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">Admin / Users</h1>
                            </div>

                            <div className="row">

                                <div className="col-xl-8 col-md-8 mb-4">
                                    <ButtonSplit style={{marginRight:15}} onClick={() => { listUsers(); viewStats(); }} icon="fa-user">
                                        {state?.usersCnt} Users
                                    </ButtonSplit>
                                    <ButtonSplit style={{marginRight:15}}  icon="fa-user">
                                        {state?.plansCnt} Plans
                                    </ButtonSplit>
                                    <a  style={{marginRight:15}}  href={internalApi.apiUrl(`user/export/?token=${storage.get('token')}`)} target="_blank" rel="noopener noreferrer" className="btn btn-icon-split btn-primary">
                                        <span className="icon text-white-50">
                                            <i className="fas fa-download"></i>
                                        </span>
                                        <span className="text">Export Users</span>
                                    </a>
                                    <ButtonSplit  style={{marginRight:15}}  onClick={() => { testEmail(); }} icon="fa-envelope">
                                        Test Email
                                    </ButtonSplit>
                                </div>
                                
                                

                            </div>
                            <div className="row">
                                <div className="col-xl-5 col-md-9">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0', borderBottom: 'solid 2px #e6e6e6', fontWeight: 'bold' }}>
                                        <div>
                                            Users
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-3 mb-4">

                                </div>
                            </div>
                            <div className="row">
                                <div style={{overflowX:'auto'}}>

                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th><Input style={{width:100}} autoTrigger={{ length: 3, delay: 2000, cb: runSearch }} value={state?.search?.name} onChange={updateSearch('name')} /></th>
                                                <th><Input style={{width:100}}  autoTrigger={{ length: 3, delay: 2000, cb: runSearch }} value={state?.search?.email} onChange={updateSearch('email')} /></th>
                                                <th><Input style={{width:100}}  autoTrigger={{ length: 3, delay: 2000, cb: runSearch }} value={state?.search?.company} onChange={updateSearch('company')} /></th>
                                                <th><Input style={{width:100}}  autoTrigger={{ length: 3, delay: 2000, cb: runSearch }} value={state?.search?.phone} onChange={updateSearch('phone')} /></th>
                                                <th><Input style={{width:100}}  autoTrigger={{ length: 3, delay: 2000, cb: runSearch }} value={state?.search?.city} onChange={updateSearch('city')} /></th>
                                                <th><Input style={{width:100}}  autoTrigger={{ length: 3, delay: 2000, cb: runSearch }} value={state?.search?.state} onChange={updateSearch('state')} /></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('firstName')}>Name</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('email')}>Email</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('company.name')}>Company</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('company.phone')}>Phone</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('company.city')}>City</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('company.state')}>State</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('createdAt')}>Created</span></th>
                                                <th><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={setOrderBy('lastLogin')}>Last Login</span></th>
                                                <th>Actions</th>
                                            </tr>
                                            {state?.users && state?.users?.map(user => (
                                                <tr key={user?.id}>
                                                    <td>{user?.firstName} {user?.lastName}</td>
                                                    <td>{user?.email}</td>
                                                    <td>
                                                        {user?.company?.name}
                                                        {!user?.company.isActive && (
                                                            <span style={{color:'crimson'}}> (Suspended)</span>
                                                        )}
                                                    </td>
                                                    <td>{user?.company?.phone}</td>
                                                    <td>{user?.company?.city}</td>
                                                    <td>{user?.company?.state}</td>
                                                    <td>{moment(user?.createdAt).format('MM/DD/YYYY HH:MM')}</td>
                                                    <td>{user?.loginHistory?.[0]?.createdAt?moment(user?.loginHistory?.[0]?.createdAt)?.format('MM/DD/YYYY HH:MM'):'No Login'}</td>
                                                    <td>{user?.company?.isActive?<ButtonSplit type="btn-warning" icon="fa-warning" onClick={() => suspendCompany(user?.companyId)}>Suspend</ButtonSplit>:<ButtonSplit onClick={() => enableCompany(user?.companyId)}>Enable</ButtonSplit>}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div style={{display:'flex',justifyContent:'center',marginBottom:100}}>
                                        <ButtonSplit disabled={state?.page <= 1} style={{marginRight:25}} onClick={viewPrevPage} icon="fa-arrow-left">
                                            Previous
                                        </ButtonSplit>
                                        <ButtonSplit disabled={state?.users?.length === 0}  alternate={true} onClick={viewNextPage} icon="fa-arrow-right">
                                            Next
                                        </ButtonSplit>
                                    </div>


                                </div>
                            </div>


                        </div>

                    </div>

                    <Footer />

                </div>

            </div>





        </React.Fragment>
    );
};

export default withRouter(Stats);