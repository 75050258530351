import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../Flex';

import classNames from 'classnames';
import cs from './component.pcss';
import DeleteValidator from './DeleteValidator';

class ButtonDelete extends React.PureComponent {
  state = {
    isOpen: false,
  };

  onToggle = () => {
    if (this.props.readonly) {
      return;
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  renderModal = () => (
    <DeleteValidator
      width={650}
      hasPadding
      isOpen={this.state.isOpen}
      onToggle={this.onToggle}
      onDelete={this.onClick}
    />
  );

  onClick = e => {
    if(!this.props.readonly ){
      this.props.onClick(e);
    }
  };

  render() {
    const { fullWidth, style: propsStyle, readonly, children, lg, transparent = false } = this.props;

    const style = {
      width: fullWidth ? '100%' : 220,
      padding: fullWidth ? '' : '0 53px',
      ...propsStyle,
    };

    if(transparent){
      return (<div 
        onClick={this.onToggle}
        role="button"
        tabIndex={0}
        style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer', display: 'inline-block', marginLeft: 10 }}>
        <Flex xs={{ justify: 'center', align: 'center', height: '100%' }}>
        <div style={{paddingLeft:'5px',paddingRight:'5px'}}>{children}</div>
        </Flex>
        {this.state.isOpen ? this.renderModal() : null}
      </div>);
    }else
    return (
      <div
        className={classNames(cs.root, {
          [cs.lg]: lg,
          [cs.readonly]: readonly,
        })}
        style={style}
        onClick={this.onToggle}
        role="button"
        tabIndex={0}
      >
        <Flex xs={{ justify: 'center', align: 'center', height: '100%' }}>
        <div style={{paddingLeft:'5px',paddingRight:'5px'}}>{children}</div>
        </Flex>
        {this.state.isOpen ? this.renderModal() : null}
      </div>
    );
  }
}

ButtonDelete.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  readonly: PropTypes.bool,
  lg: PropTypes.bool,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

ButtonDelete.defaultProps = {
  fullWidth: true,
  readonly: false,
  lg: false,
  style: {},
  onClick: () => false,
};

export default ButtonDelete;
