import { combineReducers } from 'redux';
// import { routerReducer as router } from 'react-router-redux';

import ui from './ui';
import alerts from './alerts';
import company from './company';
import auth from './auth';
import user from './user';

const rootReducer = combineReducers({
  alerts,
  ui,
  company,
  auth,
  user,
});

export default rootReducer;
