/**
 * Calculates flex basis based on 12 item grid
 * @param size
 */
const calculateSize = size => `${100 / 12 * size}%`;

/**
 * Detect configuration based on view port size
 * and given config
 * @param width Window width
 * @param config configuration object
 * @returns {*}
 */
const getViewPortConfig = (width, config) => {
  const sizes = [
    {
      name: 'xl',
      isDetected: width >= 1200,
      value: config.xl || false,
    },
    {
      name: 'lg',
      isDetected: width >= 992,
      value: config.lg || false,
    },
    {
      name: 'md',
      isDetected: width >= 768,
      value: config.md || false,
    },
    {
      name: 'sm',
      isDetected: width >= 576,
      value: config.sm || false,
    },
    {
      name: 'xs',
      isDetected: width >= 0,
      value: config.xs || false,
    },
  ];

  for (let i = 0; i < sizes.length; i += 1) {
    const size = sizes[i];
    // we detected screen size
    if (size.isDetected && size.value) {
      return size.value;
    }
  }

  return false;
};

/**
 * Return flex row styles based on config
 * Available styles
 * - align:
 * - justify:
 * - wrap:
 * - height
 * - width
 * @param config
 * @returns {{}}
 */
const getRowStyles = config => ({
  alignItems: config.align || 'stretch',
  justifyContent: config.justify || 'flex-start',
  flexWrap: config.wrap || false,
  display:config.display || 'flex',
  height: config.height || 'auto',
  width: config.width || '100%', 
});

/**
 * Return flex column styles based on config
 * When given integer calculates flex-basis
 * Available styles
 * - size:
 * - offset:
 * - left:
 * - right:
 * - top:
 * - bottom:
 * @param config
 * @returns {{}}
 */
const getColumnStyles = config => {
  if (typeof config === 'number') {
    return {
      flexBasis: calculateSize(config),
    };
  }
  let ai = {}
  if (config.display === 'flex')
    ai = {
      'alignItems': 'center'
    }
  return {
    flexBasis: config.size ? calculateSize(config.size) : 'auto',
    marginLeft: config.offset ? calculateSize(config.offset) : 0,
    display: config.display ? config.display : 'block',
    ...ai
  };
};

export { getViewPortConfig, getRowStyles, getColumnStyles, calculateSize };
