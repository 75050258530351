import _ from 'lodash';
import internalApi from '../utils/internalApi';
// import { clearAlerts } from './alerts';
import createAction from '../utils/createAction';

export const FETCH_COMPANIES_LOADED = 'FETCH_COMPANIES_LOADED';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';

const fetchCompanies = ({ page = 1, limit = 50, search = '' } = {}) => dispatch => {
  dispatch(createAction(FETCH_COMPANIES_LOADED));
  const queryParams = {
    search,
    page,
    limit
  };
  internalApi.get('company/list', { params: queryParams }).then(res => {
    dispatch(createAction(FETCH_COMPANIES, res.payload.items));
  });
};

const fetchCompany = id => () => internalApi.get(`company/one/${id}`);

const setupCompany = data => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.post(
    data.id ? `company/update/${data.id}` : 'company/create',
    _.omit(data, 'id'),
  );
};

const deleteCompanies = ids => () =>
  internalApi.post('company/delete', {
    ids,
  });

export { fetchCompanies, fetchCompany, setupCompany, deleteCompanies };
