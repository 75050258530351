import React, { useReducer,useEffect } from 'react';
import { withRouter,useParams } from 'react-router-dom';
// import { Button, Input } from './../../components/Form';
// import Validator from '../../utils/form-validator';
import basicReducer from './../../reducers/basicReducer';
import internalApi from './../../utils/internalApi';
import CreatePlanWrapper from './CreatePlanWrapper';
import Step1 from './wizard/Step1';
import Step2 from './wizard/Step2';
import Step3 from './wizard/Step3';
import Step4 from './wizard/Step4';
import Step5 from './wizard/Step5';
import Step6 from './wizard/Step6';
import Step7 from './wizard/Step7';
import Step8 from './wizard/Step8';
import Step9 from './wizard/Step9';
import Download from './wizard/Download';
import Footer from './Footer';


// let validator = new Validator({
//     companyName: {
//         required: true,
//         message: 'Company Name is required',
//     },
//     address: {
//         required: true,
//         message: 'Address is required',
//     },
//     city: {
//         required: true,
//         message: 'City is required',
//     },
//     state: {
//         required: true,
//         message: 'State is required',
//     },
//     zip: {
//         required: true,
//         message: 'Zip is required',
//     },
//     phone: {
//         required: true,
//         message: 'Phone is required',
//     },
//     firstName: {
//         required: true,
//         message: 'First Name is required',
//     },
//     lastName: {
//         required: true,
//         message: 'Last Name is required',
//     },
//     // username: {
//     //     required: true,
//     //     message: 'Username is required',
//     // },
//     email: {
//         required: true,
//         message: 'Email is required',
//     },
//     password: {
//         required: true,
//         message: 'Password is required',
//     },
//     confirmPassword: {
//         required: true,
//         message: 'Confirm password is required',
//     },
// });

const CreatePlan = () => {
    let { id } = useParams();

    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        step: 1,
    });

    let [data, setData] = useReducer(basicReducer, {
        pandemicTeam: [],
        planActivationAuthority: [],
        criticalFunctions: [],
        keyStaff: [],
        requiredResources: [],
        keySuppliersVendors: [],
        stakeHolders: [],
        contactAgencies: [],
        actionItems: []
    });

    useEffect(() => {
        console.log('pla id',id);

        internalApi.get(`plan/${id}`).then(res => {
            // console.log('setting',res);
            setData({
                pandemicTeam: [],
                planActivationAuthority: [],
                criticalFunctions: [],
                keyStaff: [],
                requiredResources: [],
                keySuppliersVendors: [],
                stakeHolders: [],
                contactAgencies: [],
                actionItems: [],
                ...res?.payload?.item?.meta
            });
        });
    },[id]);
    // let [form, setForm] = useReducer(basicReducer, {
    //     companyName: '',
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     phone: '',
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     password: '',
    //     confirmPassword: '',
    // });

    // const isValid = () => {
    //     const [isValid, errors] = validator.isValid(form);
    //     setState({
    //         errors,
    //     });
    //     return isValid;
    // }

    // const register = () => {
    //     if (isValid()) {
    //         console.log(form);
    //     } else {
    //         setState({ submitInProgress: false });
    //         console.log(state.errors);
    //     }
    // }

    const onNext = () => {
        setState({step:state.step+1});
    }
    
    const onBack = () => {
        if(state?.step > 0){
            setState({step:state.step-1});
        }
    }

    const onChange = name => d => {
        setData({[name]:d});
        internalApi.put(`plan/${id}`,{meta:{...data,[name]:d}}).then(res => {

        });
    }


    return (
        <CreatePlanWrapper>
            {state?.step === 1 && (
                <Step1 value={data?.pandemicTeam} onNext={onNext} onChange={onChange('pandemicTeam')} />
            )}
            {state?.step === 2 && (
                <Step2  value={data?.planActivationAuthority} onNext={onNext} onBack={onBack} onChange={onChange('planActivationAuthority')} />
            )}
            {state?.step === 3 && (
                <Step3  value={data?.criticalFunctions} onNext={onNext}  onBack={onBack}  onChange={onChange('criticalFunctions')} />
            )}
            {state?.step === 4 && (
                <Step4  value={data?.keyStaff} onNext={onNext}  onBack={onBack}  onChange={onChange('keyStaff')} />
            )}
            {state?.step === 5 && (
                <Step5  value={data?.requiredResources} onNext={onNext}  onBack={onBack}  onChange={onChange('requiredResources')} />
            )}
            {state?.step === 6 && (
                <Step6  value={data?.keySuppliersVendors} onNext={onNext}  onBack={onBack}  onChange={onChange('keySuppliersVendors')} />
            )}
            {state?.step === 7 && (
                <Step7  value={data?.stakeHolders} onNext={onNext}  onBack={onBack}  onChange={onChange('stakeHolders')} />
            )}
            {state?.step === 8 && (
                <Step8  value={data?.contactAgencies} onNext={onNext}  onBack={onBack}  onChange={onChange('contactAgencies')} />
            )}
            {state?.step === 9 && (
                <Step9  value={data?.actionItems} onNext={onNext}  onBack={onBack}  onChange={onChange('actionItems')} />
            )}
            {state?.step === 10 && (
                <Download planId={id} onNext={onNext}  onBack={onBack} />
            )}
            {state?.step < 10 && (
            <div className="progress progress-sm" style={{marginBottom:40}}>
                <div className="progress-bar" role="progressbar" style={{width: `${(state?.step/10)*100}%`}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            )}
            <Footer padding={0} />
        </CreatePlanWrapper>
    );
};

export default withRouter(CreatePlan);