import React,{memo} from 'react';
import Select2 from '../Select2';

const GridDropdownItem = ({fieldName,value,rowKey,colKey,options,onChange}) => {
    
    let formattedValue = value;
    if(!value){
        formattedValue = null;
    }
    return (
        <Select2
            field={{
                name: `${fieldName}-${rowKey}-${colKey}`,
            }}
            value={formattedValue}
            options={options}
            onChange={onChange}
        />
    );
};

export default memo(GridDropdownItem,(p,n) => {
    const needsToReRender = false;
    const dontRerender = true;

    if(p && n && typeof p.value !== 'undefined' && typeof n.value !== 'undefined' && p.value !== n.value){
        return needsToReRender;
    }

    if(p && n && typeof p.fieldName !== 'undefined' && typeof n.fieldName !== 'undefined' && p.fieldName !== n.fieldName){
        return needsToReRender;
    }
    
    if(p && n && typeof p.rowKey !== 'undefined' && typeof n.rowKey !== 'undefined' && p.rowKey !== n.rowKey){
        return needsToReRender;
    }

    if(p && n && typeof p.colKey !== 'undefined' && typeof n.colKey !== 'undefined' && p.colKey !== n.colKey){
        return needsToReRender;
    }
    
    if(p && n && typeof p.options !== 'undefined' && typeof n.options !== 'undefined' && p.options.length !== n.options.length){
        return needsToReRender;
    }

    return dontRerender;
});