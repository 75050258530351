import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
// import { PrivateRoute } from '../../components/Routing';

// import App from '../app';
import SignIn from '../auth/login';
import Register from '../auth/register';
import ForgotPass from '../auth/forgot-pass';
import ResetPassword from '../auth/reset-password';
import Home from '../app/home';
import EditProfile from '../app/EditProfile';
import AdminUsers from '../app/admin/users';
import CreatePlan from '../app/CreatePlan';
import storage from '../../utils/storage';
// import ForgotPassword from '../auth/forgot-password';
// import LogoutPage from '../auth/logout';
// import AutoLoginByToken from '../auth/auto-login-by-token';

const MainRoutes = props => {
  // console.log('props.token->',props.token);
  return (
    <BrowserRouter>
      <Switch>
        {!props.token && (
          <Fragment>
            <Route path="/" exact component={SignIn} />
            <Route path="/register" exact component={Register} />
            <Route path="/forgot-pass" exact component={ForgotPass} />
            <Route path="/reset-password/:token" exact component={ResetPassword} />
            {/* <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/logout" exact component={LogoutPage} /> */}
            {/* <Route path="/token" exact component={AutoLoginByToken} /> */}
          </Fragment>
        )}
        {props.token && (
          <Fragment>
            {storage.get('role') === 0 && (
              <Route path="/admin/users" exact component={AdminUsers} />
            )}
            <Route path="/home" exact component={Home} />
            <Route path="/profile" exact component={EditProfile} />
            <Route path="/create-plan/:id" exact component={CreatePlan} />
          </Fragment>
        )}
        {/* {props.token && <PrivateRoute component={App} />} */}
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = state => ({
  token: state.auth.get('token'),
});

export default connect(mapStateToProps)(MainRoutes);
