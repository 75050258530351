import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fa';

import styles from './component.pcss';
import {strengthIndicator,strengthColor,hasNumber,hasSpecial,hasLowerCase,hasUpperCase,startOrEndDoesnotContainSpaces} from './../../utils/passwordStrength';

const totalStrength = 7;

class PasswordStrendth extends React.Component {

    componentWillReceiveProps(nextProps){
        if(this.props.onChange && nextProps.value !== this.props.value){
            const strength = strengthIndicator(nextProps.value);
            this.props.onChange((totalStrength - strength === 0)?true:false);
        }
    }

    render() {
        const {value = '',minLength} = this.props;
        const strength = strengthIndicator(value);
        const color = strengthColor(strength);

        //if(!value)return null;

        const calculatedWidth = ((strength/totalStrength)*100)+'%';

        return (
            <div className={styles.strengthMeter}>
                <div style={{padding:'10px'}}>
                    <div className={styles.items}><Icon style={{ color: value.length >= minLength?'green':'#efefef' }} name="check" /> Be at least {minLength} characters</div>
                    <div className={styles.items}><Icon style={{ color: hasUpperCase(value)?'green':'#efefef'  }} name="check" /> Include an uppercase letter</div>
                    <div className={styles.items}><Icon style={{ color: hasLowerCase(value)?'green':'#efefef'  }} name="check" /> Include a lower case letter</div>
                    <div className={styles.items}><Icon style={{ color: hasSpecial(value)?'green':'#efefef'  }} name="check" /> Include a special letter</div>
                    <div className={styles.items}><Icon style={{ color: hasNumber(value)?'green':'#efefef' }} name="check" /> Include a number</div>
                    <div className={styles.items}><Icon style={{ color: startOrEndDoesnotContainSpaces(value)?'green':'#efefef'  }} name="check" /> Not start or end with a space</div>
                </div>
                <div style={{backgroundColor:'#efefef'}}>
                    <div style={{backgroundColor:color,height:'5px',width:calculatedWidth,borderRadius:'0px',margin:'0px'}}></div>
                </div>
            </div>
        );
    }
}

PasswordStrendth.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    minLength: PropTypes.number,
    style: PropTypes.shape({}),
};

PasswordStrendth.defaultProps = {
    className: '',
    value: '',
    minLength: 8,
    style: {},
};

export default PasswordStrendth;
