import React from 'react';
import PropTypes from 'prop-types';

const Padding = props => {
  const style = {
    paddingTop: props.padding.top || 0,
    paddingRight: props.padding.right || 0,
    paddingBottom: props.padding.bottom || 0,
    paddingLeft: props.padding.left || 0,
  };

  return (
    <div style={style} className={props.className}>
      {props.children}
    </div>
  );
};

Padding.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default Padding;
