import React, { useReducer } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import basicReducer from './../../reducers/basicReducer';
import { Input, Button } from './../../components/Form';
import Validator from '../../utils/form-validator';
import { toast } from 'react-toastify';
import internalApi from './../../utils/internalApi';
import logo from './../../assets/images/logo.png';
import pandemicLogo from './../../assets/images/pandemic-logo.svg';

let validator = new Validator({
    email: {
        required: true,
        message: 'Email is required',
    },
});

const Login = () => {
    let history = useHistory();
    let rootDispatch = useDispatch();
    let [state, setState] = useReducer(basicReducer, {
        errors: {}
    });
    let [form, setForm] = useReducer(basicReducer, {
        email: '',
    });

    const isValid = () => {
        const [isValid, errors] = validator.isValid(form);
        setState({
            errors,
        });
        return { isValid, errors };
    }

    const retrievePassword = () => {
        const isValidResponse = isValid();

        if (!isValidResponse.isValid) {
            Object.keys(isValidResponse.errors).forEach(key => {
                toast.error(isValidResponse.errors[key]);
            });
            return;
        }

        internalApi
            .post(
                {
                    server: 'auth',
                    path: '/forgot-password',
                },
                form,
            )
            .then(({ payload }) => {
                toast.success(payload.message);
            });
    };


    return (
        <div className="container">

            <div className="row justify-content-center">

                <div className="col-xl-10 col-lg-12 col-md-9">

                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block">
                                    <div style={{textAlign:'center',paddingTop: 90}}>
                                        <img alt="" src={logo} />
                                        <div style={{marginTop: 25,fontWeight: 'bold',fontSize: 26}}>
                                            {/* Pandemic Planning Tool */}
                                            <img src={pandemicLogo} alt="" width="320" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Forgot Password</h1>
                                        </div>
                                        <form className="user">
                                            <div className="form-group">
                                                <Input
                                                    type="email"
                                                    className="form-control form-control-user"
                                                    value={form?.email} name="email"
                                                    onChange={email => setForm({ email })}
                                                    placeholder="Enter email address" />
                                            </div>
                                            <Button onClick={retrievePassword} className="btn btn-primary btn-user btn-block">
                                            Retrieve Password
                                            </Button>
                                            {/* <hr /> */}
                                            {/* <a href="index.html" className="btn btn-google btn-user btn-block">
                                                <i className="fab fa-google fa-fw"></i> Login with Google
                    </a>
                                            <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                                <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                    </a> */}
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <Link className="small" to="/">Login</Link>
                                        </div>
                                        <div className="text-center">
                                            <Link className="small" to="/register">
                                                Create an Account!
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default withRouter(Login);