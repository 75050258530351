import React, { useEffect, useReducer } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import createAction from '../../utils/createAction';
import {isSanitizedTxt} from '../../utils/common';

import { Modal } from './../../components';
import { Button, Input, ButtonSplit, ButtonDelete } from './../../components/Form';
import internalApi from './../../utils/internalApi';
import storage from './../../utils/storage';
import toast from './../../utils/toast';
import basicReducer from './../../reducers/basicReducer';
import { CLEAR_TOKEN } from '../../actions/auth';
import Footer from './Footer';
import logo from './../../assets/images/logo.png';
// import pandemicLogo from './../../assets/images/pandemic-logo.png';
import pandemicLogo from './../../assets/images/pandemic-logo.svg';

const Home = props => {
    let history = useHistory();
    let rootDispatch = useDispatch();
    const [state, setState] = useReducer(basicReducer, {
        planName: '',
        showPlan: false,
        planItems: []
    });

    const closePlanModal = () => {
        setState({ showPlan: false });
    }

    const showPlanModal = () => {
        setState({ showPlan: true });
    }

    const createPlan = () => {
        if (state?.planName) {

            if(!isSanitizedTxt(state?.planName)){
                toast('Script tags are not allowed','error');
                return;
            }
      
            closePlanModal();
            internalApi.post('plan', { name: state?.planName })
                .then(res => {
                    setState({ planName: '' });
                    listPlans();
                    // console.log('res-> ', res);
                });
        }else{
            toast('Plan name cannot be empty','error');
            // closePlanModal();

        }

    }

    const editPlan = planId => {
        history.push(`/create-plan/${planId}`);
    }

    const logout = () => {
        storage.remove('token');
        storage.remove('role');
        rootDispatch(createAction(CLEAR_TOKEN, { token: null }));
        history.push('/');
    }

    const deletePlan = planId => {
        internalApi.delete(`plan/${planId}`)
            .then(res => {
                listPlans();
            });
    }

    const listPlans = () => {
        internalApi.get('plan')
            .then(res => {
                // console.log('res-> ', res);
                setState({ planItems: res?.payload?.items });
            });
    }

    useEffect(() => {
        listPlans();
    }, []);

    return (
        <React.Fragment>

            <div id="wrapper" style={{ height: '100vh' }}>


                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">

                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                            <img src={logo} height="40" alt="Continuity Innovations" />

                            <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                                <img src={pandemicLogo} />
                            </div>
                            {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form> */}

                            <ul className="navbar-nav ml-auto">

                                <li className="nav-item dropdown no-arrow d-sm-none">
                                    <Button className="nav-link dropdown-toggle" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-search fa-fw"></i>
                                    </Button>
                                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                                        <form className="form-inline mr-auto w-100 navbar-search">
                                            <div className="input-group">
                                                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fas fa-search fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </li>




                                <li className="nav-item">
                                    <a href="mailto:help@continuityinnovations.com" className="nav-link">
                                        <span style={{ textAlign: 'center' }} className="mr-2 d-none d-lg-inline text-gray-600 small"> <i className="fas fa-support"></i> Help</span>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="/profile" className="nav-link">
                                        <span style={{ textAlign: 'center' }} className="mr-2 d-none d-lg-inline text-gray-600 small"> <i className="fas fa-user"></i> Profile</span>
                                    </Link>
                                </li> */}

                                <div className="topbar-divider d-none d-sm-block"></div>

                                <li className="nav-item dropdown no-arrow">
                                    <Button style={{ cursor: 'pointer',textAlign: 'center' }} onClick={logout} className="nav-link">
                                        <span style={{ textAlign: 'center' }} className="d-none d-lg-inline text-gray-600 small"> <i className="fas fa-power-off"></i> Logout</span>
                                        {/* <img alt="" className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" /> */}
                                    </Button>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">

                                        <Button className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                            <i style={{ textAlign: 'center' }} className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                        </Button>
                                    </div>
                                </li>

                            </ul>

                        </nav>

                        <div className="container-fluid">

                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">Home</h1>
                                {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-plus fa-sm text-white-50"></i> Create Plan</a> */}
                                {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-plus fa-sm text-white-50"></i> View Plans</a> */}
                            </div>

                            <div className="row">

                                <div className="col-xl-2 col-md-3 mb-4">
                                    <ButtonSplit icon="fa-plus" onClick={showPlanModal}>
                                        Create Plan
                                    </ButtonSplit>
                                </div>

                                {/* <div className="col-xl-2 col-md-3 mb-4">
                                    <a href="#" className="btn btn-primary btn-icon-split">
                                        <span className="icon text-white-50">
                                        <i className="fas fa-eye"></i>
                                        </span>
                                        <span className="text">View Plans</span>
                                    </a>
                                </div> */}

                            </div>
                            <div className="row">
                                <div className="col-xl-5 col-md-9">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0', borderBottom: 'solid 2px #e6e6e6', fontWeight: 'bold' }}>
                                        <div>
                                            Plan Name
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-3 mb-4">

                                </div>
                            </div>
                            {state?.planItems && state?.planItems?.map(plan => (
                                <div key={plan?.id} className="row">
                                    <div className="col-xl-5 col-md-9 mb-4">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, borderRadius: 4, backgroundColor: '#e6e6e6' }}>
                                            <div>
                                                <Button style={{ cursor: 'pointer' }} onClick={() => editPlan(plan?.id)}>{plan?.name}</Button>
                                            </div>
                                            <div>
                                                <a href={internalApi.apiUrl(`plan/download/${plan?.id}?token=${storage.get('token')}`)} target="_blank"><i style={{ cursor: 'pointer', marginRight: 20 }} className="fas fa-download"></i></a>
                                                <i onClick={() => editPlan(plan?.id)} style={{ cursor: 'pointer', marginRight: 10 }} className="fas fa-edit"></i>
                                                <ButtonDelete transparent onClick={() => deletePlan(plan?.id)} ><i style={{ cursor: 'pointer', color: 'crimson' }} className="fas fa-trash"></i></ButtonDelete>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-3 mb-4">

                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>

                    <Footer />

                </div>

            </div>


            {state?.showPlan && (
                <Modal isOpen={true} onClose={closePlanModal}>
                    <div className="modal-header" style={{ padding: 0 }}>
                        <h5 className="modal-title" id="exampleModalLabel">Create new plan</h5>
                        <button onClick={closePlanModal} className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <form className="user">
                            <div className="form-group">
                                <Input value={state?.planName} onChange={v => setState({ planName: v })} type="text" className="form-control form-control-user" id="planName" aria-describedby="planName" placeholder="Plan Name" />
                            </div>
                            {/* <a href="index.html" className="btn btn-primary btn-user btn-block">
                                        Create
                                    </a> */}
                        </form>
                    </div>
                    <div className="modal-footer" style={{ padding: 0 }}>
                        <Button onClick={closePlanModal} className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</Button>
                        <Button onClick={createPlan} className="btn btn-primary" href="login.html">Create</Button>
                    </div>

                </Modal>
                // <div className="modal fade show" style={{ display: 'block' }} id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

                // </div>
            )}


        </React.Fragment>
    );
};

export default withRouter(Home);