import { List, Map } from 'immutable';

import * as actionTypes from '../actions/company-settings';
import * as companyActionTypes from '../actions/company';

const initialState = new Map({
  info: {},
  publicInfo: {},
  companies: new Map({
    isLoaded: false,
    items: new List(),
  }),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_INFO: {
      return state.set('info', action.payload);
    }
    
    case actionTypes.GET_COMPANY_PUBLIC_INFO: {
      return state.set('publicInfo', {});
    }
    
    case actionTypes.GET_COMPANY_PUBLIC_INFO_LOADED: {
      return state.update('publicInfo', () => action.payload);
    }

    case companyActionTypes.FETCH_COMPANIES_LOADED: {
      const { loaded = false } = action.payload;

      return state.updateIn(['companies', 'isLoaded'], () => loaded);
    }
    case companyActionTypes.FETCH_COMPANIES: {
      return state.update(
        'companies',
        () =>
          new Map({
            isLoaded: true,
            items: new List(action.payload.records),
            total: action.payload.total,
          }),
      );
    }
    default:
      return state;
  }
};
