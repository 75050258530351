import { Map, List } from 'immutable';
import * as actionTypes from '../actions/alerts';

const initialState = new Map({
  errors: new List(),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ALERTS: {
      return state.set('errors', new List());
    }
    case actionTypes.PUSH_ERROR: {
      return state.updateIn(['errors'], data =>
        data.unshift({
          message: action.payload.message,
        }),
      );
    }
    default:
      return state;
  }
};
