import { Map } from 'immutable';

import * as actionTypes from '../actions/ui';

const initialState = new Map({
  libraryModalIsOpen: false,
  riskMatrixModalIsOpen: false,
  addHistoryModalIsOpen: false,
  createHistoryItemModalIsOpen: false,
  addPlanFromTemplateModalIsOpen: false,
  sidebarIsClose: false,
  notificationIsOpen: false,
  filterWidgetIsOpen: false,
  pages: [
    [
      {
        name: 'Dashboard',
        url: 'dashboard',
        icon: 'dashboard.png',
      },
      {
        name: 'Libraries',
        icon: 'library.png',
        items: [],
      },
     //  {
      //   name: 'Analytics',
      //   url: 'analytics',
      //   icon: 'new-analytics.png',
      //   permission: 'perm_analytics_view',
      // },
    ],
    [
      {
        name: 'Reports',
        url: 'reports',
        icon: 'reports.png',
        permission: 'perm_reports_view',
      },
      {
        name: 'Tasks',
        url: 'task-management',
        icon: 'tasks1.png',
        permission: 'perm_task_view',
      },
      // {
      //   name: 'ToDo List',
      //   url: 'todo',
      //   icon: 'todo.png',
      //   permission: 'perm_todolist_view',
      // },
      {
        name: 'Manage Approvals',
        url: 'approval-history',
        icon: 'task-management.png',
        permission: 'perm_manage_approval_view',
      },
      {
        name: 'Administration',
        icon: 'administration.png',
        permission: 'perm_superadmin_view',
        items: [
          {
            name: 'Companies',
            url: 'company',
            icon: 'companies.png',
            permission: 'superadmin_manager',
          },
          {
            name: 'Company Settings',
            url: 'company-settings',
            icon: 'company-settings.png',
            permission: 'perm_company_settings_view',
          },
          {
            name: 'Login History',
            url: 'login-history',
            icon: 'login-history.png',
            permission: 'perm_login_history_view',
          },
          {
            name: 'Import',
            url: 'import',
            icon: 'import.png',
            permission: 'perm_import_view',
          },
          {
            name: 'Roles',
            url: 'roles',
            icon: 'roles.png',
            permission: 'perm_roles_view',
          },
          {
            name: 'Access Groups',
            url: 'groups',
            icon: 'groups.png',
            permission: 'perm_roles_view',
          },
          {
            name: 'Users',
            url: 'user-setup',
            icon: 'user-setup.png',
            permission: 'perm_users_view',
          },
          {
            name: 'Library Management',
            url: 'library-management',
            icon: 'library-management.png',
            permission: 'perm_library_management_view',
          },
          {
            name: 'Threats',
            url: 'threats',
            icon: 'threats.png',
            permission: 'perm_threat_view',
          },
          {
            name: 'Help',
            url: 'help',
            icon: 'help-center.png',
            permission: 'perm_help_questions_view',
          },
          {
            name: 'Plan Templates',
            url: 'plan-template',
            icon: 'plan-template.png',
            permission: 'perm_superadmin_view',
          },
        ],
      },
      // {
      //   name: 'Help Center',
      //   url: 'help-center',
      //   icon: 'help-center.png',
      //   permission: 'perm_help_center_view',
      // },
    ],
  ],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UI_TOGGLE_PARAM: {
      const { param } = action.payload;
      const value = state.get(param);

      if (!state.has(param) || typeof value !== 'boolean') {
        throw new Error('Something went wrong while toggling value');
      }

      return state.set(param, !value);
    }
    case actionTypes.UI_SET_PARAM: {
      const { param } = action.payload;

      if (!state.has(param)) {
        throw new Error('Something went wrong while toggling value');
      }

      //console.log("setting ",state.get(param),param,action.payload.value)
      if(state.get(param) === action.payload.value){
        return state;
      }

      return state.set(param, action.payload.value);
    }
    default:
      return state;
  }
};
