import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'react-fa';
import Flex from '../../Flex';

import cs from './component.pcss';

class ButtonAction extends React.PureComponent {
  onClick = e => (this.props.readonly ? false : this.props.onClick(e));

  render() {
    const { icon, fullWidth, primary, danger, style: propsStyle } = this.props;

    const style = {
      width: fullWidth ? '100%' : 220,
      padding: fullWidth ? '' : '0 53px',
      ...propsStyle,
    };

    return (
      <div
        className={`${cs.root} ${primary ? cs.primary : ''} ${
          danger ? cs.danger : ''
        }`}
        style={style}
        onClick={this.onClick}
        role="button"
        tabIndex={0}
      >
        <Flex xs={{ justify: 'center', align: 'center', height: '100%' }}>
          <Icon name={icon} />
        </Flex>
      </div>
    );
  }
}

ButtonAction.propTypes = {
  icon: PropTypes.string,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  fullWidth: PropTypes.bool,
  readonly: PropTypes.bool,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

ButtonAction.defaultProps = {
  icon: '',
  fullWidth: true,
  primary: false,
  danger: false,
  readonly: false,
  style: {},
  onClick: () => false,
};

export default ButtonAction;
