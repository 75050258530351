import React from 'react';
import PropTypes from 'prop-types';

class Radio extends React.Component {
  onChange = ({ target }) => {
    const { disabled, field, onChange } = this.props;

    if (disabled) {
      return;
    }

    const { name, value } = target;

    onChange(value, name, field);
  };

  render() {
    const { value, checked, label, disabled, field } = this.props;

    const id = `${field.name}-${value}`;

    return (
      <label htmlFor={id}>
        <input
          type="radio"
          id={id}
          name={field.name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={this.onChange}
          onClick={this.props.onClick}
        />
        <span style={{ marginLeft: 10 }}>{label || value}</span>
      </label>
    );
  }
}

Radio.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Radio.defaultProps = {
  disabled: false,
  value: '',
  checked: false,
  label: null,
  onChange: () => false,
  onClick: () => false,
};

export default Radio;
