import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { isImmutable, List } from 'immutable';
import _ from 'lodash';

//import 'react-select/dist/react-select.css';

class Select2 extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
    ]),
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.instanceOf(List),
    ]).isRequired,
    readOnly: PropTypes.bool,
    fixedPosition: PropTypes.bool,
    clearable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    field: {
      name: '',
      type: 'String',
    },
    value: '',
    fixedPosition: true,
    readOnly: false,
    clearable: false,
  };

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.value !== nextProps.value){
      return true;
    }
    
    if(this.props.options !== nextProps.options){
      return true;
    }
    
    if(this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name){
      return true;
    }

    return false;
  }


  onChange = selectedOption => {
    const { value } = selectedOption;
    if (value !== '') {
      this.props.onChange(value, this.props.field.name);
    }
  };

  render() {
    const options = this.props.options.map(option => {
      if (typeof option !== 'object') {
        return {
          value: option,
          label: option,
        };
      }
      return option;
    });

    const { field, value, readOnly,fixedPosition } = this.props;
    const formattedOptions = isImmutable(options) ? options.toJS() : options;

    // console.log("select 2 rendered", field);

    const selectedOption = (value || value === 0)
      ? _.find(formattedOptions, item => item.value === value)
      : null;
    
      if(fixedPosition){
        return (
          <div>
            <Select
              name={field.name}
              value={selectedOption}
              options={formattedOptions}
              isDisabled={readOnly}
              isClearable={this.props.clearable}
              onChange={this.onChange}
              menuPosition="fixed"
              menuPortalTarget={document.body}
            />
          </div>
        );
      }else{
        return (
          <div>
            <Select
              name={field.name}
              value={selectedOption}
              options={formattedOptions}
              isDisabled={readOnly}
              isClearable={this.props.clearable}
              onChange={this.onChange}
            />
          </div>
        );
      }

  }
}

export default Select2;
