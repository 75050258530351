import React, { memo } from 'react';

const Footer = ({padding = 20}) => {
    
    return (
        <footer className="sticky-footer bg-white" style={{padding}}>
            <div className="copyright my-auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 25px' }}>
                <span>Copyright &copy; 2020 Continuity Innovations. All rights reserved.</span>
                <span>Need a more robust planning solution? Learn about <a target="_blank" rel="noopener noreferrer" href="https://continuityinnovations.com/bcp-software/">ContinuityPRO</a></span>
            </div>
        </footer>
    );

};

export default memo(Footer);