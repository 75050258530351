import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './component.pcss';

const Alert = props => {
  const style = {
    ...props.style,
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.primary]: props.primary,
        [styles.success]: props.success,
        [styles.danger]: props.danger,
        [styles.critical]: props.critical,
        [styles.medium]: props.medium,
        [styles.warning]: props.warning,
        [styles.lg]: props.lg,
      })}
      style={style}
    >
      {props.children}
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  critical: PropTypes.bool,
  medium: PropTypes.bool,
  lg: PropTypes.bool,
  style: PropTypes.shape({}),
};

Alert.defaultProps = {
  primary: false,
  success: false,
  warning: false,
  danger: false,
  critical: false,
  medium: false,
  lg: false,
  style: {},
};

export default Alert;
