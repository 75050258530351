import React from 'react';
import PropTypes from 'prop-types';
import { getViewPortConfig, getColumnStyles } from '../../../utils/flex';

class Col extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      style: {},
      viewPortConfig: {},
    };

    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);

    // Initial resize call
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    const { xl, lg, md, sm, xs } = this.props;

    if (!xl && !lg && !md && !sm && !xs) {
      return;
    }

    const viewPortConfig = getViewPortConfig(window.innerWidth, {
      xl,
      lg,
      md,
      sm,
      xs,
    });

    this.setState({
      viewPortConfig,
      style: getColumnStyles(viewPortConfig),
    });
  }

  render() {
    let w = {};
    if (this.state.viewPortConfig.display === 'flex')
      w = {
        width: '100%'
      };
    const wrapperStyle = {
      paddingLeft: this.state.viewPortConfig.left || 0,
      paddingRight: this.state.viewPortConfig.right || 0,
      paddingTop: this.state.viewPortConfig.top || 0,
      paddingBottom: this.state.viewPortConfig.bottom || 0,
      display: this.state.viewPortConfig.display || 'block',
      textAlign: this.state.viewPortConfig.textAlign || '',
      ...w
    };

    return (
      <div style={this.state.style} className={this.props.className}>
        <div style={wrapperStyle}>{this.props.children}</div>
      </div>
    );
  }
}

const shape = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.shape({
    size: PropTypes.number,
    offset: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    display: PropTypes.string,
    textAlign:PropTypes.string,
  }),
]);

Col.propTypes = {
  xl: shape,
  lg: shape,
  md: shape,
  sm: shape,
  xs: shape,
  children: PropTypes.node,
  className: PropTypes.string,
};

Col.defaultProps = {
  xl: null,
  lg: null,
  md: null,
  sm: null,
  xs: null,
  className: '',
};

export default Col;
