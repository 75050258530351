import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import storage from '../utils/storage';

// import { clearAlerts } from './alerts';
import _ from 'lodash';

export const AUTHENTICATE = 'AUTHENTICATE';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const FETCH_LOGIN_HISTORY_LOADED = 'FETCH_LOGIN_HISTORY_LOADED';
export const GET_LOGIN_HISTORY = 'GET_LOGIN_HISTORY';

const signIn = (authData,routerHistory) => dispatch => {
  // dispatch(clearAlerts());

  internalApi
    .post(
      {
        server: 'auth',
        path: '/authenticate',
      },
      authData,
    )
    .then(res => {
      const {token,expired,passwordResetUrl } = res.payload.item;
      
      if(expired){
        window.top.location = passwordResetUrl;
        return;
      }

      storage.set('token', token);
      dispatch(createAction(AUTHENTICATE, res.payload.item));

      if(routerHistory){
        routerHistory.push('/dashboard');
      }
    }).catch(err => {
      console.log("err ",err);
    });
};

const signInByExternalToken = (token,routerHistory) => dispatch => {
  // dispatch(clearAlerts());
  
  storage.set('token', token);
  dispatch(createAction(AUTHENTICATE, {token}));

  if(routerHistory){
    routerHistory.push('/dashboard');
  }
};

const clearToken = () => createAction(CLEAR_TOKEN);

const getLoginHistory = ({ order = {}, limit, offset, search = '',individualSearchFields= [] } = {}) => dispatch => {
  dispatch(createAction(FETCH_LOGIN_HISTORY_LOADED));

  const queryParams = {
    search,
    orderBy: order.key,
    sortBy: order.in,
    limit,
    offset,
    individualSearchFields
  };

  internalApi.get('user/login-history', { params: queryParams }).then(res => {
    dispatch(createAction(GET_LOGIN_HISTORY, res.payload.items));
  });
};

const deleteLoginHistory = ids => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.post('user/login-history', {
    ids,
  });
};

const signUp = data => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.post(
    {
      server: 'auth',
      path: '/register',
    },
    _.omit(data, 'confirmPassword'),
  );
};

export { signIn, signUp, clearToken, getLoginHistory, deleteLoginHistory,signInByExternalToken };
