import defaultConfig from '../config/default';
import getEnvironment from './getEnvironment';

const env = getEnvironment();
const environmentConfig = require(`../config/${env}`).default;

const config = Object.assign({}, defaultConfig, environmentConfig);

export default path => {
  const pathParts = path.split('.');

  return pathParts.reduce((parent, current) => parent[current], config);
};
