import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../Flex';
import Loading from '../../Loading';

import classNames from 'classnames';
// import cs from './component.pcss';

class Button extends React.PureComponent {
  onClick = e => (this.props.readonly ? false : this.props.onClick(e));

  render() {
    const {
      fullWidth,
      className,
      // primary,
      // success,
      // danger,
      style: propsStyle,
      readonly,
      children,
      // lg,
      // sm,
      isLoading
    } = this.props;

    const style = {
      width: fullWidth ? '100%' : 220,
      padding: fullWidth ? '' : '0 53px',
      fontWeight: 700,
      ...propsStyle,
    };

    return (
      <div
        // className={classNames(cs.root, {
        //   [cs.lg]: lg,
        //   [cs.sm]: sm,
        //   [cs.primary]: primary,
        //   [cs.danger]: danger,
        //   [cs.success]: success,
        //   [cs.readonly]: readonly,
        // })}
        className={classNames(className,{
          readonly: readonly,
        })}
        style={style}
        onClick={this.onClick}
        role="button"
        tabIndex={0}
      >
        { isLoading && (
        <div style={{position:'relative',height: '10px',width: '40px',padding: '0px',left: '45%',top: '0px',transform: 'translateX(-50%) translateY(-50%)'}}>
          <Loading size="sm" fixed={false} type="ball-pulse-sync" />
        </div>
        )}
        { !isLoading && (
          <Flex xs={{ justify: 'center', align: 'center', height: '100%' }}>
            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>{children}</div>
          </Flex>
        )}

      </div>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  success: PropTypes.bool,
  fullWidth: PropTypes.bool,
  readonly: PropTypes.bool,
  isLoading: PropTypes.bool,
  lg: PropTypes.bool,
  sm: PropTypes.bool,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  fullWidth: true,
  primary: false,
  danger: false,
  success: false,
  readonly: false,
  isLoading: false,
  lg: false,
  sm: false,
  style: {},
  onClick: () => false,
};

export default Button;
