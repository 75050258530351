import React, { useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, ButtonSplit, ButtonDelete } from './../../../components/Form';
// import { Card } from './../../../components';
// import Validator from '../../../utils/form-validator';
import basicReducer from './../../../reducers/basicReducer';
import TableDisplay from './TableDisplay';

// let validator = new Validator({
//     companyName: {
//         required: true,
//         message: 'Company Name is required',
//     },
// });

const Step2 = ({ value, onChange, onNext, onBack }) => {
    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        editItem: null,
        screen: 'list',//(value && value.length > 0) ? 'list' : 'add'
    });
    let [form, _setForm] = useReducer(basicReducer, {
        priority: '',
        criticalFunctionName: '',
    });

    const save = () => {
        let anyFilled = Object.keys(form).filter(k => form[k]).length;
        if(anyFilled){
            if(state.editItem !== null){
                let arr = [...value];
                arr.splice(state.editItem,1,form);
                onChange([...arr]);
                setState({screen:'list',editItem:null});
            }else{
                onChange([...value,form]);
                setState({screen:'list',editItem:null});
            }
        }
    }

    const deleteItem = (item,index) => {
        let arr = [...value];
        arr.splice(index,1);
        onChange([...arr]);
    }

    const addItem = () => {
        _setForm({
            priority: '',
            criticalFunctionName: '',
        });
        setState({ screen: 'add' });
    }
    
    const editItem = (item,index) => {
        _setForm({
            ...item
        });
        setState({ screen: 'add',editItem:index });
    }

    const setForm = value => {
        _setForm(value);
    }

    // const isValid = () => {
    //     const [isValid, errors] = validator.isValid(form);
    //     setState({
    //         errors,
    //     });
    //     return isValid;
    // }

    // const register = () => {
    //     if (isValid()) {
    //         console.log(form);
    //     } else {
    //         setState({ submitInProgress: false });
    //         console.log(state.errors);
    //     }
    // }

    let sortedList = value?[...value]:[];
    sortedList.sort((a,b) => {
        return a?.priority - b?.priority;
    })

    return (
        <React.Fragment>
            <div className="text-center">
                <h1 className="h4 text-gray-900 mb-4">Critical Functions</h1>
            </div>
            <div className="my-2"></div>
            <div>
            In this section please list all critical (essential) functions by priority. Critical functions can be described as important activities performed by an organization that must remain operational in order to prevent impact to the business/entity.  During a crisis, less essential functions may need to be placed on hold in order to focus on critical business functions. 
            <br />
            Note: The Priority column will automatically be ordered from lowest to highest.

            </div>
            <div className="my-2"></div>
            {state?.screen === 'list' && (
            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                <ButtonSplit icon="fa-plus" onClick={addItem}>Add Item</ButtonSplit>
            </div>
            )}
            <div className="my-2"></div>
            <div style={{padding:'20px 0'}}>
                {state?.screen === 'list' && (
                    <form className="user">
                        {value && value?.length === 0 && (
                            <div className="form-group row">
                                 <div className="col-sm-12">
                                     0 Items
                                 </div>
                            </div>
                        )}
                        <TableDisplay value={sortedList} editItem={editItem} deleteItem={deleteItem} />
                        <div className="form-group row">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                            <Button onClick={onBack} className="btn btn-secondary btn-user btn-block">Back</Button>
                            </div>
                            <div className="col-sm-3">
                                <Button onClick={onNext} className="btn btn-primary btn-user btn-block">Next</Button>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>

                    </form>
                )}
            </div>
            
            {state?.screen === 'add' && (
                <div className="user" style={{marginBottom: 40}}>
                    <div className="form-group row">
                        <div className="col-sm-4 mb-3 mb-sm-0">
                            <Input type="number" pattern='[0-9]' className="form-control form-control-user" value={form.priority} name="priority" onChange={priority => setForm({ priority })} placeholder="Priority" />
                        </div>
                        <div className="col-sm-4">
                            <Input type="text" className="form-control form-control-user" value={form.criticalFunctionName} name="criticalFunctionName" onChange={criticalFunctionName => setForm({ criticalFunctionName })} placeholder="Critical Function Name" />
                        </div>
                    </div>
                    <div className="row">
                        <div style={{ padding: 5 }}>
                            <Button onClick={() => setState({ screen: 'list' })} className="btn btn-secondary btn-user btn-block">Cancel</Button>
                        </div>
                        <div style={{ padding: 5 }}>
                            <Button onClick={save} className="btn btn-primary btn-user btn-block">Save</Button>
                        </div>
                    </div>

                </div>
            )}

        </React.Fragment>
    );
};

export default withRouter(Step2);