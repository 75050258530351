import React, { useReducer } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import basicReducer from './../../reducers/basicReducer';
import { PasswordStrength } from './../../components';
import { Input, Button } from './../../components/Form';
import Validator from '../../utils/form-validator';
import { toast } from 'react-toastify';
import internalApi from './../../utils/internalApi';
import logo from './../../assets/images/logo.png';

let validator = new Validator({
    password: {
        required: true,
        message: 'Password is required',
    },
    passwordConfirmation: {
        required: true,
        message: 'Password Confirmation is required',
    },
});

const ResetPassword = () => {
    let { token } = useParams();
    let history = useHistory();
    let [state, setState] = useReducer(basicReducer, {
        strengthMeterVisible: false,
        isStrongPassword: false,
        errors: {}
    });
    let [form, setForm] = useReducer(basicReducer, {
        password: '',
        confirmPassword: '',
        token
    });

    const backToLogin = () => {
        history.push('/');
    }

    const isValid = () => {
        const [isValid, errors] = validator.isValid(form);
        setState({
            errors,
        });
        return { isValid, errors };
    }

    const resetPassword = () => {
        const isValidResponse = isValid();

        if (!isValidResponse.isValid) {
            Object.keys(isValidResponse.errors).forEach(key => {
                toast.error(isValidResponse.errors[key]);
            });
            // toast('Validation errors', 'error');
            return;
        }

        if (form.password.length < 8) {
            toast.error(`Password should be at least 8 characters`);
            return;
        }

        if (!state.isStrongPassword) {
            toast.error(`Password is not strong enough`);
            return;
        }

        if (form.password !== form.confirmPassword) {
            toast.error("Password confirmation doesn't match Password");
            return;
        }

        internalApi
            .post(
                {
                    server: 'auth',
                    path: '/reset-password',
                },
                form,
            )
            .then(({ payload }) => {
                toast.success(payload.message);
                setState({
                    isChanged: true,
                    expired: false
                });
            });
    };


    return (
        <div className="container">

            <div className="row justify-content-center">

                <div className="col-xl-10 col-lg-12 col-md-9">

                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block">
                                    <div style={{textAlign:'center',paddingTop: '35%'}}>
                                        <img src={logo} alt="" />
                                        <div style={{marginTop: 25,fontWeight: 'bold',fontSize: 26}}>
                                            Pandemic Planning Tool
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Reset Password</h1>
                                        </div>
                                        {!state?.isChanged && (
                                            <form className="user">
                                                <div className="form-group">
                                                    <Input
                                                        type="password"
                                                        className="form-control form-control-user"
                                                        value={form?.password} name="password"
                                                        onFocus={() => setState({ strengthMeterVisible: true })}
                                                        onBlur={() => setState({ strengthMeterVisible: false })}
                                                        onChange={password => setForm({ password })}
                                                        placeholder="Password" />
                                                    <div style={{ position: 'relative' }}>
                                                        {state?.strengthMeterVisible && (
                                                            <PasswordStrength
                                                                value={form?.password}
                                                                minLength={8}
                                                                onChange={isStrongPassword => setState({ isStrongPassword })}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <Input type="password" className="form-control form-control-user" value={form.confirmPassword} onChange={confirmPassword => setForm({ confirmPassword })} name="confirmPassword" placeholder="Repeat Password" />
                                                </div>

                                                <Button onClick={resetPassword} className="btn btn-primary btn-user btn-block">
                                                    Reset Password
                </Button>
                                            </form>
                                        )}
                                        {state?.isChanged && (
                                            <Button onClick={backToLogin} className="btn btn-primary btn-user btn-block">
                                                Back to Login
                                            </Button>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default withRouter(ResetPassword);