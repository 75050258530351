import React, { useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, ButtonSplit } from './../../../components/Form';
// import { Card } from './../../../components';
// import Validator from '../../../utils/form-validator';
import basicReducer from './../../../reducers/basicReducer';
import TableDisplay from './TableDisplay';

// let validator = new Validator({
//     companyName: {
//         required: true,
//         message: 'Company Name is required',
//     },
// });

const Step7 = ({ value, onChange, onNext, onBack }) => {
    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        editItem: null,
        screen: 'list',//(value && value.length > 0) ? 'list' : 'add'
    });
    let [form, _setForm] = useReducer(basicReducer, {
        name: '',
        phone: '',
        email: '',
        description: '',
    });

    const save = () => {
        let anyFilled = Object.keys(form).filter(k => form[k]).length;
        if (anyFilled) {
            if (state.editItem !== null) {
                let arr = [...value];
                arr.splice(state.editItem, 1, form);
                onChange([...arr]);
                setState({ screen: 'list', editItem: null });
            } else {
                onChange([...value, form]);
                setState({ screen: 'list', editItem: null });
            }
        }
    }

    const deleteItem = (item,index) => {
        let arr = [...value];
        arr.splice(index,1);
        onChange([...arr]);
    }

    const addItem = () => {
        _setForm({
            name: '',
            phone: '',
            email: '',
            description: ''
        });
        setState({ screen: 'add' });
    }

    const editItem = (item, index) => {
        _setForm({
            ...item
        });
        setState({ screen: 'add', editItem: index });
    }

    const setForm = value => {
        _setForm(value);
    }

    // const isValid = () => {
    //     const [isValid, errors] = validator.isValid(form);
    //     setState({
    //         errors,
    //     });
    //     return isValid;
    // }

    // const register = () => {
    //     if (isValid()) {
    //         console.log(form);
    //     } else {
    //         setState({ submitInProgress: false });
    //         console.log(state.errors);
    //     }
    // }

    return (
        <React.Fragment>
            <div className="text-center">
                <h1 className="h4 text-gray-900 mb-4">Stakeholders</h1>
            </div>
            <div className="my-2"></div>
            <div>
            In this section please list any Key Stakeholders that may need to be notified of the event or kept in the loop during the event.  For example, Board of Directors, Regulators, etc.
            </div>
            <div className="my-2"></div>
            {state?.screen === 'list' && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <ButtonSplit icon="fa-plus" onClick={addItem}>Add Item</ButtonSplit>
            </div>
            )}
            <div className="my-2"></div>
            <div style={{ padding: '20px 0' }}>
                {state?.screen === 'list' && (
                    <form className="user">
                        {value && value?.length === 0 && (
                            <div className="form-group row">
                                 <div className="col-sm-12">
                                     0 Items
                                 </div>
                            </div>
                        )}
                        <TableDisplay value={value} editItem={editItem} deleteItem={deleteItem} />
                        <div className="form-group row">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                                <Button onClick={onBack} className="btn btn-secondary btn-user btn-block">Back</Button>
                            </div>
                            <div className="col-sm-3">
                                <Button onClick={onNext} className="btn btn-primary btn-user btn-block">Next</Button>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>

                    </form>
                )}
            </div>

            {state?.screen === 'add' && (
                <div className="user" style={{marginBottom: 40}}>
                    <div className="form-group row">
                        <div className="col-sm-4 mb-3 mb-sm-0">
                            <Input type="text" className="form-control form-control-user" value={form.name} name="name" onChange={name => setForm({ name })} placeholder="Name" />
                        </div>
                        <div className="col-sm-4">
                            <Input type="text" className="form-control form-control-user" value={form.phone} name="phone" onChange={phone => setForm({ phone })} placeholder="Phone" />
                        </div>
                        <div className="col-sm-4">
                            <Input type="text" className="form-control form-control-user" value={form.email} name="email" onChange={email => setForm({ email })} placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Input type="text" className="form-control form-control-user" value={form.description} name="description" onChange={description => setForm({ description })} placeholder="Description" />
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ padding: 5 }}>
                            <Button onClick={() => setState({ screen: 'list' })} className="btn btn-secondary btn-user btn-block">Cancel</Button>
                        </div>
                        <div style={{ padding: 5 }}>
                            <Button onClick={save} className="btn btn-primary btn-user btn-block">Save</Button>
                        </div>
                    </div>

                </div>
            )}

        </React.Fragment>
    );
};

export default withRouter(Step7);