import { toast as notify } from 'react-toastify';
import React from 'react';

const toast = (message, notifyType = 'info') => {
  let type;
  switch (notifyType) {
    case 'success':
      type = notify.TYPE.SUCCESS;
      break;
    case 'error':
      type = notify.TYPE.ERROR;
      break;
    case 'warning':
      type = notify.TYPE.WARNING;
      break;
    default:
      type = notify.TYPE.INFO;
  }
  return notify(<Msg message={(message && typeof message === 'string')?message.split('\n'):['something went wrong!']} />, {
    type,
    position: notify.POSITION.TOP_RIGHT
  });
};

const Msg = (props) => (
  <div>
    {props.message && props.message.map((mes, key) => {
      return mes.length > 0 ? <p key={key}>{mes}</p> : null;
    })}
  </div>
)

export default toast;
