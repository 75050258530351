import React, { useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, ButtonSplit, Checkbox } from './../../../components/Form';
// import { Card } from './../../../components';
import {toCamelCase} from '../../../utils/common';
import basicReducer from './../../../reducers/basicReducer';
import TableDisplay from './TableDisplay';
import ImportModal from './ImportModal';

// let validator = new Validator({
//     companyName: {
//         required: true,
//         message: 'Company Name is required',
//     },
// });

const initFields = () => {
    let f = {
        name: '',
        phone: '',
        email: '',
        department: '',
        remoteWork: 'no'
    };
    return {...f};
}

let dataLeftToImport = [];

const Step2 = ({ value, onChange, onNext, onBack }) => {
    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        editItem: null,
        importModalVisible:false,
        screen: 'list',//(value && value.length > 0) ? 'list' : 'add'
    });
    let [form, _setForm] = useReducer(basicReducer, initFields());

    const save = () => {
        let anyFilled = Object.keys(form).filter(k => form[k]).length;
        if (anyFilled) {
            if (state.editItem !== null) {
                let arr = [...value];
                arr.splice(state.editItem, 1, form);
                onChange([...arr]);
                setState({ screen: 'list', editItem: null });
            } else {
                onChange([...value, form]);
                setState({ screen: 'list', editItem: null });
            }
        }
    }

    const showImportModal = () => {
        setState({importModalVisible:true});
    }

    const hideImportModal = () => {
        setState({importModalVisible:false});
    };
    
    const onImportRow = (data) => {
        let allowedFields = Object.keys(initFields());
        let anyFilled = Object.keys(data).filter(k => data[k]).map(toCamelCase).filter(k => allowedFields.indexOf(k) !== -1).length;
        if (anyFilled) {
            let dataToSave = {};
            allowedFields.forEach(field => {
                dataToSave[field] = '';
            });
            Object.keys(data).map(k => ({k,camelK:toCamelCase(k)})).filter(v => allowedFields.indexOf(v?.camelK) !== -1).forEach(v => {
                dataToSave[v?.camelK] = data[v?.k];
            })
            dataLeftToImport.push(dataToSave);
            //onChange([...value, dataToSave]);
        }
    }

    const onImportFinish = (data) => {
        onImportRow(data);
        onChange([...value, ...dataLeftToImport]);
        dataLeftToImport = [];
        setState({importModalVisible:false});
    }

    const deleteItem = (item, index) => {
        let arr = [...value];
        arr.splice(index, 1);
        onChange([...arr]);
    }

    const addItem = () => {
        _setForm(initFields());
        setState({ screen: 'add' });
    }

    const editItem = (item, index) => {
        _setForm({
            ...item
        });
        setState({ screen: 'add', editItem: index });
    }

    const setForm = value => {
        _setForm(value);
    }

    // const isValid = () => {
    //     const [isValid, errors] = validator.isValid(form);
    //     setState({
    //         errors,
    //     });
    //     return isValid;
    // }

    // const register = () => {
    //     if (isValid()) {
    //         console.log(form);
    //     } else {
    //         setState({ submitInProgress: false });
    //         console.log(state.errors);
    //     }
    // }

    return (
        <React.Fragment>
            <div className="text-center">
                <h1 className="h4 text-gray-900 mb-4">Key Staff</h1>
            </div>
            <div className="my-2"></div>
            <div>
            In this section please list any Key Staff that will be essential for recovery. Please consider the individuals remote work capabilities, such as, proper equipment, access to critical systems, a conducive remote work environment, etc.
            </div>
            <div className="my-2"></div>
            {state?.screen === 'list' && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <ButtonSplit style={{marginRight:15}} type="btn-secondary" icon="fa-download" onClick={showImportModal}>Import</ButtonSplit>
                    <ButtonSplit icon="fa-plus" onClick={addItem}>Add Item</ButtonSplit>
                </div>
            )}
            <div className="my-2"></div>
            <div style={{ padding: '20px 0' }}>
                {state?.screen === 'list' && (
                    <form className="user">
                        {value && value?.length === 0 && (
                            <div className="form-group row">
                                <div className="col-sm-12">
                                    0 Items
                                 </div>
                            </div>
                        )}
                        <TableDisplay value={value} editItem={editItem} deleteItem={deleteItem} />
                        <div className="form-group row">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                                <Button onClick={onBack} className="btn btn-secondary btn-user btn-block">Back</Button>
                            </div>
                            <div className="col-sm-3">
                                <Button onClick={onNext} className="btn btn-primary btn-user btn-block">Next</Button>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>

                    </form>
                )}
            </div>

            {state?.screen === 'add' && (
                <div className="user" style={{marginBottom: 40}}>
                    <div className="form-group row">
                        <div className="col-sm-8 mb-3 mb-sm-0">
                            <Input type="text" className="form-control form-control-user" value={form.name} name="name" onChange={name => setForm({ name })} placeholder="Name" />
                        </div>
                        
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-8">
                            <Input type="text" className="form-control form-control-user" value={form.department} name="department" onChange={department => setForm({ department })} placeholder="Department" />
                        </div>
                        
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-8">
                            <Checkbox field={{name:'remoteWork'}} checked={form?.remoteWork === 'yes'?true:false}  onChange={remoteWork => setForm({ remoteWork:remoteWork?'yes':'no' })} /> <span style={{cursor:'pointer',marginLeft:5}} onClick={() => { setForm({remoteWork:form?.remoteWork === 'yes'?'no':'yes'})  }}>Has Remote Work Capability?</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-8">
                            <Input type="text" className="form-control form-control-user" value={form.phone} name="phone" onChange={phone => setForm({ phone })} placeholder="Phone" />
                        </div>
                        
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-8">
                            <Input type="email" className="form-control form-control-user" value={form.email} name="email" onChange={email => setForm({ email })} placeholder="Email" />
                        </div>
                    </div>


                    <div className="row">
                        <div style={{ padding: 5 }}>
                            <Button onClick={() => setState({ screen: 'list' })} className="btn btn-secondary btn-user btn-block">Cancel</Button>
                        </div>
                        <div style={{ padding: 5 }}>
                            <Button onClick={save} className="btn btn-primary btn-user btn-block">Save</Button>
                        </div>
                    </div>

                </div>
            )}

            {state?.importModalVisible && (
                <ImportModal fields={initFields()} onImportRow={onImportRow} onImportFinish={onImportFinish} onClose={hideImportModal} />
            )}

        </React.Fragment>
    );
};

export default withRouter(Step2);