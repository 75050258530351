import internalApi from '../utils/internalApi';
import createAction from '../utils/createAction';
import exportCSVFile from '../utils/exportCSVFile';

// import { clearAlerts } from './alerts';
import storage from '../utils/storage';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS_LOADED = 'FETCH_USERS_LOADED';
export const CLEAR_USER = 'CLEAR_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER_FIELDS = 'FETCH_USER_FIELDS';
export const SET_USERS_FIELDS = 'SET_USERS_FIELDS';
export const TOGGLE_USER_LOCKED_STATUS = 'TOGGLE_USER_LOCKED_STATUS';


const fetchUser = () => dispatch => {
  internalApi.get('user/me').then(res => {
    dispatch(createAction(FETCH_USER, res.payload.item));
  });
};

const getUserById = id => () => internalApi.get(`user/user/${id}`);

const getUsers = ({ order = {}, page = 1, limit = 50, search = '',individualSearchFields=[] } = {}) => dispatch => {
  dispatch(createAction(FETCH_USERS_LOADED));

  const queryParams = {
    orderBy: order.key,
    sortBy: order.in,
    page,
    limit,
    search,
    individualSearchFields
  };

  internalApi.get('user/list', { params: queryParams }).then(res => {
    dispatch(createAction(FETCH_USERS, res.payload.items));
  });
};

const saveUser = data => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.post(
    data.id ? `user/user/${data.id}` : 'user/create',
    data,
  );
};

const deleteUser = id => () => internalApi.post(`user/delete/${id}`);

const deleteUsers = ids => () =>
  internalApi.post('user/delete-multiple', {
    ids,
  });

const clearUser = () => createAction(CLEAR_USER);

const saveSettings = data => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.put('user/me', data);
};

const changePassword = data => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.put('user/me/password', data);
};

const onColumnToggle = (fieldId, value) => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.post(`user/fields/${fieldId}/hidden-toggle`, {
    value: !value,
  });
};

const exportUsersCSV = checkedItems => () => {
  internalApi
    .get('user/export', {
      params: { checkedItems: checkedItems.join(',') },
    })
    .then(res => {
      exportCSVFile(res.payload.item, 'Users');
    });
};

const exportCSV = checkedItems => () => {
  internalApi
    .get('user/login-history/export', {
      params: { checkedItems: checkedItems.join(',') },
    })
    .then(res => {
      exportCSVFile(res.payload.item, 'login-history');
    });
};

const getProfilePicture = () => dispatch => {
  // dispatch(clearAlerts());

  return internalApi.get('user/profile-picture');
};

const onColumnToggleUsers = (fieldId, value) => (dispatch, getState) => {
  const state = getState();
  const fields = state.user.get('fields');

  const newFields = fields.map(field => {
    if (field.id === fieldId) {
      return { ...field, hidden: !value };
    }
    return { ...field };
  });

  storage.set('usersFields', newFields);

  dispatch(createAction(SET_USERS_FIELDS, newFields));
};

const toggleLockedStatus = userId => dispatch => {

  internalApi
    .post(`user/${userId}/toggleLocked`)
    .then(res => {
      dispatch(createAction(TOGGLE_USER_LOCKED_STATUS,{userId}));
    }).catch(err => {
      console.log("err ",err);
    });
};


export {
  fetchUser,
  getUserById,
  getUsers,
  exportUsersCSV,
  clearUser,
  deleteUser,
  saveUser,
  saveSettings,
  changePassword,
  deleteUsers,
  onColumnToggle,
  exportCSV,
  getProfilePicture,
  onColumnToggleUsers,
  toggleLockedStatus
};
