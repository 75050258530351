import React, {Suspense} from 'react';
import { Provider } from 'react-redux';

import AppRoutes from './routes';
import { Loading } from './../../components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Call it once in your app. At the root of your app is the best place
toast.configure({
  autoClose: 4000,
  draggable: false,
  //etc you get the idea
});

class Root extends React.Component {

  render() {
    return (
        <Provider store={this.props.store}>
          <Suspense fallback={<Loading fixed={false} size="sm" type="ball-pulse-sync" />}>
            <AppRoutes />
          </Suspense>
        </Provider>
    );
  }
}

export default Root;
