import React, { useReducer,useEffect } from 'react';
import { withRouter,useParams } from 'react-router-dom';
// import { Button, Input } from './../../components/Form';
// import Validator from '../../utils/form-validator';
import basicReducer from './../../reducers/basicReducer';
import internalApi from './../../utils/internalApi';
import CreatePlanWrapper from './CreatePlanWrapper';
import Footer from './Footer';


// let validator = new Validator({
//     companyName: {
//         required: true,
//         message: 'Company Name is required',
//     },
//     address: {
//         required: true,
//         message: 'Address is required',
//     },
//     city: {
//         required: true,
//         message: 'City is required',
//     },
//     state: {
//         required: true,
//         message: 'State is required',
//     },
//     zip: {
//         required: true,
//         message: 'Zip is required',
//     },
//     phone: {
//         required: true,
//         message: 'Phone is required',
//     },
//     firstName: {
//         required: true,
//         message: 'First Name is required',
//     },
//     lastName: {
//         required: true,
//         message: 'Last Name is required',
//     },
//     // username: {
//     //     required: true,
//     //     message: 'Username is required',
//     // },
//     email: {
//         required: true,
//         message: 'Email is required',
//     },
//     password: {
//         required: true,
//         message: 'Password is required',
//     },
//     confirmPassword: {
//         required: true,
//         message: 'Confirm password is required',
//     },
// });

const EditProfile = () => {
    let { id } = useParams();

    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        step: 1,
    });

    let [data, setData] = useReducer(basicReducer, {
        pandemicTeam: [],
        planActivationAuthority: [],
        criticalFunctions: [],
        keyStaff: [],
        requiredResources: [],
        keySuppliersVendors: [],
        stakeHolders: [],
        contactAgencies: [],
        actionItems: []
    });

    useEffect(() => {
        // console.log('pla id',id);

        internalApi.get(`plan/${id}`).then(res => {
            // console.log('setting',res);
            setData({
                pandemicTeam: [],
                planActivationAuthority: [],
                criticalFunctions: [],
                keyStaff: [],
                requiredResources: [],
                keySuppliersVendors: [],
                stakeHolders: [],
                contactAgencies: [],
                actionItems: [],
                ...res?.payload?.item?.meta
            });
        });
    },[id]);
    // let [form, setForm] = useReducer(basicReducer, {
    //     companyName: '',
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     phone: '',
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     password: '',
    //     confirmPassword: '',
    // });

    // const isValid = () => {
    //     const [isValid, errors] = validator.isValid(form);
    //     setState({
    //         errors,
    //     });
    //     return isValid;
    // }

    // const register = () => {
    //     if (isValid()) {
    //         console.log(form);
    //     } else {
    //         setState({ submitInProgress: false });
    //         console.log(state.errors);
    //     }
    // }

    const onNext = () => {
        setState({step:state.step+1});
    }
    
    const onBack = () => {
        if(state?.step > 0){
            setState({step:state.step-1});
        }
    }

    const onChange = name => d => {
        setData({[name]:d});
        internalApi.put(`plan/${id}`,{meta:{...data,[name]:d}}).then(res => {

        });
    }


    return (
        <CreatePlanWrapper>
            Edit Profile            
            <Footer padding={0} />
        </CreatePlanWrapper>
    );
};

export default withRouter(EditProfile);