import React from 'react';
import PropTypes from 'prop-types';

//import DatePicker from 'react-datepicker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import moment from 'moment';
//import cx from 'classnames';
//import cs from './component.pcss';
import './datepicker-input.scss';

class InputDate extends React.Component {
  onChange = (selectedDay, modifiers, dayPickerInput) => {
    this.props.onChange(selectedDay ? moment(selectedDay).toDate() : null);
  };

  render() {
    const { selected, datepickerProps, /*lg, invalid*/ } = this.props;
    const value = selected? moment(selected).format('YYYY-M-D'):'';
    //const value = selected ? moment(selected).format('M/D/YYYY') : '';
  
    //{cx(cs.root, {
    //   [cs.lg]: lg,
    //   [cs.invalid]: invalid,
    // })}
    return (
      <DayPickerInput
        showOutsideDays
        onDayChange={this.onChange}
        //selectedDay={moment(selected).format('YYYY-M-D')}
        value={value}
        placeholder='YYYY-MM-DD'
        {...datepickerProps}
      />
    );
  }
}

InputDate.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]),
  lg: PropTypes.bool,
  invalid: PropTypes.string,
  datepickerProps: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

InputDate.defaultProps = {
  selected: null,
  lg: false,
  invalid: '',
  datepickerProps: {},
};

export default InputDate;
