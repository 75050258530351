import React from 'react';
import PropTypes from 'prop-types';
// import RichTextEditor from 'react-rte';

class TextEditor extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    onChange: () => false,
  };

  // constructor(props) {
    // super(props);

    // this.state = {
    //   value: RichTextEditor.createValueFromString(
    //     props.value || props.defaultValue,
    //     'html',
    //   ),
    // };

  // }

  // componentWillReceiveProps(nextProps){
  //   //console.log("richtext: -> ",nextProps);
  //   if(typeof this.props.value !== 'undefined' && typeof nextProps.value !== 'undefined' && this.props.value !== nextProps.value && this.props.value.trim() == ''){
  //   this.setState({
  //       value: RichTextEditor.createValueFromString(
  //         nextProps.value || this.props.defaultValue,
  //         'html',
  //       ),
  //       });
  //   }
  // }

  // onChange = value => {
  //   this.setState({ value });
  //   this.props.onChange(value.toString('html'), this.props.field.name);
  // };

  render() {

    // console.log("wysiwyg editor ", this.props.field);

    return (
      <div style={{wordBreak: 'break-word',zIndex:0,position:'relative'}}>
        Slate replacement here
        {/* <RichTextEditor
          value={this.state.value}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
        /> */}
      </div>
    );
  }
}

export default TextEditor;
