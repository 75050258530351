import React from 'react';
import { withRouter,useHistory } from 'react-router-dom';


const CreatePlanWrapper = ({ children }) => {
    let history = useHistory();

    const goBackHome = () => {
        history.push('/home');
    }

    return (
        <div className="container">
            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="p-5">
                                {children}
                                <div onClick={goBackHome} style={{position:'absolute',top:20,right:40,cursor:'pointer'}}><i className="fas fa-close"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CreatePlanWrapper);