
import React, { memo } from 'react';
import classnames from 'classnames';

const ButtonSplit = ({style = {},type = 'btn-success',icon = 'fa-check',children,onClick,disabled = false,alternate = false}) => {
    
    const _onClick = () => {
        if(!disabled){
            onClick();
        }
    }
    
    
    if(!alternate){
        return (
            <button disabled={disabled} style={style} onClick={_onClick} className={classnames("btn btn-icon-split",type)}>
                <span className="icon text-white-50">
                    <i className={classnames("fas",icon)}></i>
                </span>
                <span className="text">{children}</span>
            </button>
        );
    }else{
        return (
            <button disabled={disabled} style={style} onClick={_onClick} className={classnames("btn btn-icon-split",type)}>
                <span className="text">{children}</span>
                <span className="icon text-white-50">
                    <i className={classnames("fas",icon)}></i>
                </span>
                
            </button>
        ); 
    }

};

export default memo(ButtonSplit);