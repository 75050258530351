import React,{memo} from 'react';
// import styles from './ImportModal.module.scss';
import { Modal } from './../../../../components';
import { UploadInput } from './../../../../components/Form';
import { camelPad } from './../../../../utils/common';
import Papa from 'papaparse';

const ImportModal = ({fields = {},onClose,onImportRow,onImportFinish}) => {
   
    let formattedFields = Object.keys(fields).map((field,index) => camelPad(field))?.join(', ');

    const onSelectFile = (e) => {
        // console.log(e);
        Papa.parse(e[1], {
            header: true,
            step: function(row) {
                // console.log("Row:", row.data);
                if(row?.data){
                    onImportRow(row?.data);
                }
            },
            complete: function(results) {
                // console.log("Finished:", results.data);
                onImportFinish(results.data);
            }
        });
    }

    return (
        <Modal isOpen={true} onClose={onClose}>
            <div onClick={onClose} style={{cursor:'pointer',position:'absolute',top:10,right:20}}>
                <i className="fas fa-close"></i>
            </div>
            <div style={{marginTop:10}}>
                Please upload a csv file that has header fields matching below:
                <div>
                    <span>{formattedFields}</span>
                </div> 
            </div>
            <UploadInput label="Upload .csv" onUpload={(...e) => onSelectFile(e)} accept=".csv" />
        </Modal>
    );

};

export default memo(ImportModal);