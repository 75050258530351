import React, { useReducer } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Input, Checkbox } from './../../components/Form';
import Validator from '../../utils/form-validator';
import basicReducer from './../../reducers/basicReducer';
import { PasswordStrength } from './../../components';

import internalApi from './../../utils/internalApi';
import logo from './../../assets/images/logo.png';
import pandemicLogo from './../../assets/images/pandemic-logo.svg';

let validator = new Validator({
    companyName: {
        required: true,
        message: 'Company Name is required',
    },
    address: {
        required: true,
        message: 'Address is required',
    },
    city: {
        required: true,
        message: 'City is required',
    },
    state: {
        required: true,
        message: 'State is required',
    },
    zip: {
        required: true,
        message: 'Zip is required',
    },
    phone: {
        required: true,
        message: 'Phone is required',
    },
    firstName: {
        required: true,
        message: 'First Name is required',
    },
    lastName: {
        required: true,
        message: 'Last Name is required',
    },
    email: {
        required: true,
        message: 'Email is required',
    },
    // password: {
    //   required: true,
    //   message: 'Password is required',
    // },
    // confirmPassword: {
    //   required: true,
    //   message: 'Confirm password is required',
    // },
});

const Register = () => {
    let history = useHistory();
    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        agree: false,
        strengthMeterVisible: false,
        isStrongPassword: false,
    });
    let [form, setForm] = useReducer(basicReducer, {
        companyName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
        // password: '',
        // confirmPassword: '',
    });

    const isValid = () => {
        let [isValid, errors] = validator.isValid(form);

        if (!state?.agree) {
            errors.agree = 'You have to agree to our terms and conditions before you can proceed to register.';
            isValid = false;
        }

        setState({
            errors,
        });
        return { isValid, errors };
    }

    const register = () => {

        const isValidResponse = isValid();

        if (!isValidResponse.isValid) {
            setState({ submitInProgress: false });

            Object.keys(isValidResponse.errors).forEach(key => {
                toast.error(isValidResponse.errors[key]);
            });
            return;
        }

        // if (form.password.length < 8) {
        //     toast.error(`Password should be at least 8 characters`);
        //     return;
        // }

        // if (!state.isStrongPassword) {
        //     toast.error(`Password is not strong enough`);
        //     return;
        // }

        // if (form.password !== form.confirmPassword) {
        //     toast.error("Password confirmation doesn't match Password");
        //     return;
        // }

        // console.log(form);
        internalApi.post({ server: 'auth/', path: 'register' }, form).then(res => {
            // console.log('response',res);
            toast.success(res.payload.message || 'Success!');
            setTimeout(() => {
                history.push('/');
            },3000);
        }).catch(err => {
            // console.log(err);
            // toast('Something went wrong');
        });




    }


    return (
        <div className="container">

            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5 d-none d-lg-block">
                            <div style={{ textAlign: 'center', paddingTop: '70%' }}>
                                <img alt="" src={logo} />
                                <div style={{ marginTop: 25, fontWeight: 'bold', fontSize: 26 }}>
                                    {/* Pandemic Planning Tool */}
                                    <img src={pandemicLogo} alt="" width="320" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                                </div>
                                <form className="user">
                                    <div className="form-group">
                                        <Input type="text" className="form-control form-control-user" value={form.companyName} name="companyName" onChange={companyName => setForm({ companyName })} placeholder="Company Name" />
                                    </div>
                                    <div className="form-group">
                                        <Input type="text" className="form-control form-control-user" value={form.address} name="address" onChange={address => setForm({ address })} placeholder="Address" />
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <Input type="text" className="form-control form-control-user" value={form.city} name="city" onChange={city => setForm({ city })} placeholder="City" />
                                        </div>
                                        <div className="col-sm-6">
                                            <Input type="text" className="form-control form-control-user" value={form.state} name="state" onChange={state => setForm({ state })} placeholder="State" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <Input type="text" className="form-control form-control-user" value={form.zip} name="zip" onChange={zip => setForm({ zip })} placeholder="Zip" />
                                        </div>
                                        <div className="col-sm-6">
                                            <Input type="text" className="form-control form-control-user" value={form.phone} name="phone" onChange={phone => setForm({ phone })} placeholder="Phone" />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">Administrator Details</h1>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <Input type="text" className="form-control form-control-user" value={form.firstName} name="firstName" onChange={firstName => setForm({ firstName })} placeholder="First Name" />
                                        </div>
                                        <div className="col-sm-6">
                                            <Input type="text" className="form-control form-control-user" value={form.lastName} name="lastName" onChange={lastName => setForm({ lastName })} placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Input type="email" className="form-control form-control-user" value={form.email} name="email" onChange={email => setForm({ email })} placeholder="Email Address" />
                                    </div>
                                    {/* <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <Input 
                                                type="password" 
                                                className="form-control form-control-user" 
                                                value={form.password} 
                                                name="password" 
                                                onFocus={() => setState({ strengthMeterVisible: true })}
                                                onBlur={() => setState({ strengthMeterVisible: false })}
                                                onChange={password => setForm({ password })} 
                                                placeholder="Password" />
                                            <div style={{ position: 'relative' }}>
                                                {state?.strengthMeterVisible && (
                                                    <PasswordStrength
                                                        value={form?.password}
                                                        minLength={8}
                                                        onChange={isStrongPassword => setState({ isStrongPassword })}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <Input type="password" className="form-control form-control-user" value={form.confirmPassword} onChange={confirmPassword => setForm({ confirmPassword })} name="confirmPassword" placeholder="Repeat Password" />
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <div>*By signing up, you agree to our <a href="https://continuityinnovations.com/termsofuse/" rel="noopener noreferrer" target="_blank">Terms of Use</a> and acknowledge that you read our <a href="https://continuityinnovations.com/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a></div>
                                        <div>
                                            <Checkbox field={{ name: 'agree' }} checked={state.agree} onChange={agree => setState({ agree })} />
                                            <span onClick={() => { setState({ agree: !state?.agree }) }} style={{ cursor: 'pointer', marginLeft: 5 }}>I agree</span>
                                        </div>
                                    </div>
                                    {/* <a href="login.html" className="btn btn-primary btn-user btn-block"> */}
                                    <Button onClick={register} className="btn btn-primary btn-user btn-block">Register Account</Button>
                                    {/* </a> */}
                                    {/* <hr />
                                                            <a href="index.html" className="btn btn-google btn-user btn-block">
                                                                <i className="fab fa-google fa-fw"></i> Register with Google
                </a>
                                                            <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                                                <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                </a> */}
                                </form>
                                <hr />
                                {/* <div className="text-center">
                                    <Link className="small" to="forgot-password">Forgot Password?</Link>
                                </div> */}
                                <div className="text-center">
                                    <Link className="small" to="/">Already have an account? Login!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default withRouter(Register);