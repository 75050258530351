import React, { memo } from 'react';

const Card = ({children,footer = null}) => {

    return (
        <div class="card mb-4">
            {/* <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Dropdown Card Example</h6>
            </div> */}
            <div class="card-body">
                {children}
            </div>
            {footer && (
            <div class="card-footer py-3 d-flex flex-row align-items-center justify-content-flex-end">
                {footer}
                {/* <h6 class="m-0 font-weight-bold text-primary"></h6> */}
            </div>
            )}
        </div>
    );

};

export default memo(Card);