export function hasOnlyNumbers(str) {
    return /^\d+$/.test(str);
}

export const  lowerFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1);
export const  upperFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);


export const camelPad = (str) => {
    return str
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, function (str) { return str.toUpperCase(); })
        .trim();
}

export const toCamelCase = (str) => {
    var retVal = '';

    retVal = str.trim()
      .replace(/[^A-Za-z]/g, ' ') /* clean up non-letter characters */
      .replace(/(.)/g, function (a, l) { return l.toLowerCase(); })
      .replace(/(\s.)/g, function (a, l) { return l.toUpperCase(); })
      .replace(/[^A-Za-z\u00C0-\u00ff]/g, '');

    return retVal
}

export const toSnakeCase = function(oStr) {
    var upperChars = oStr.match(/([A-Z])/g);
    if (! upperChars) {
        return oStr;
    }

    var str = oStr.toString();
    for (var i = 0, n = upperChars.length; i < n; i++) {
        str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
    }

    if (str.slice(0, 1) === '-') {
        str = str.slice(1);
    }

    return str;
};

export const createFieldNameFromDisplayName = (name = '') => lowerFirstLetter(name)
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '');

export const createDisplayNameFromFieldName = (name = '') => toSnakeCase(name).split('-').map(i => upperFirstLetter(i)).join(' ')

export const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function parseLookupValue(value = ''){
    //console.log("##########################");
    //console.log("about to parse ", value);
    //console.log("##########################");
    const _parse = v => {
        if(v){
            let formattedValues = v;
            formattedValues = formattedValues.replace(/lookup@/,'');
            formattedValues = formattedValues.replace(/##/g,'"');
            formattedValues = formattedValues.replace(/---/g,' ');
            formattedValues = formattedValues.replace(/___/g,',');
            let obj = {v:v};
            try{
                let obj1 = JSON.parse(formattedValues);
                if(obj1){
                    obj = obj1;
                }
            }catch(e){
                //console.error(`Error parsing value`,v);
            }
            //console.log("Returning =>",obj.v);
            return obj.v;
        }else{
            //console.log("Returning =>",v);

            return v;
        }
    };
    if(value && typeof value === 'string' && value.indexOf('lookup@') !== -1){
        let result = value.split(',').map(v =>_parse(v)).join(', ');
        //console.log("$Returning => ",result)
        return result;        
    }else{
        //console.log("@Returning => ",value);
        return value;
    }
}

export function parseLookupKey(value = ''){
    //console.log("##########################");
    //console.log("about to parse ", value);
    //console.log("##########################");
    const _parse = v => {
        if(v){
            let formattedValues = v;
            formattedValues = formattedValues.replace(/lookup@/,'');
            formattedValues = formattedValues.replace(/##/g,'"');
            formattedValues = formattedValues.replace(/---/g,' ');
            formattedValues = formattedValues.replace(/___/g,',');
            let obj = {v:v};
            try{
                let obj1 = JSON.parse(formattedValues);
                if(obj1){
                    obj = obj1;
                }
            }catch(e){
                //console.error(`Error parsing value`,v);
            }
            //console.log("Returning =>",obj.v);
            return obj.id;
        }else{
            //console.log("Returning =>",v);

            return v;
        }
    };
    if(value && typeof value === 'string' && value.indexOf('lookup@') !== -1){
        let result = value.split(',').map(v =>_parse(v)).join(', ');
        //console.log("$Returning => ",result)
        return result;        
    }else{
        //console.log("@Returning => ",value);
        return value;
    }
}

export function generateLookupID(id,value){
    let obj = {id:id,v:value};
    let str = JSON.stringify(obj);
    str = str.replace(/"/g,'##');
    str = str.replace(/\s/g,'---');
    str = str.replace(/,/g,'___');
    return `lookup@${str}`;
}

export const calculateAverage = values => {
    if(values && values.length){
      const avg = values.map(v => {
        let out = 0;
        if(typeof v !== 'undefined' && v !== null){
            out = parseInt(v);
        }
        return out;
    }).reduce((prev,current) => {
          return prev+current;
      },0);

      return (avg / values.length).toFixed(1);
    }else{
      return 0;
    }
  }

export const isSanitizedTxt = str => {
    let isValid = true;
    ['<script>','</script>'].forEach(forbidden => {
        if(str.toLowerCase().indexOf(forbidden) !== -1){
            isValid = false;
        }
    });

    return isValid;
}