import React from 'react';
import PropTypes from 'prop-types';

import path from 'path';
import classNames from 'classnames';

import styles from './component.pcss';

import imgNoImage from '../../assets/images/no-library.png';

class Image extends React.Component {
  render() {
    let { src } = this.props;
    const { className, circle, stat, imageProps = {} } = this.props;

    if (stat) {
      src = path.join('/static/images/', src);
    }

    return (
      <img
        className={classNames(className, styles.root, {
          [styles.circle]: circle,
        })}
        alt=""
        src={src || imgNoImage}
        {...imageProps}
        style={this.props.style}
      />
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  circle: PropTypes.bool,
  stat: PropTypes.bool,
  imageProps: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

Image.defaultProps = {
  className: '',
  src: null,
  circle: false,
  stat: false,
  imageProps: {},
  style: {},
};

export default Image;
