import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from './../../../components/Form';
// import basicReducer from './../../../reducers/basicReducer';
import internalApi from './../../../utils/internalApi';
import storage from './../../../utils/storage';

const Download = ({ planId, value, onChange, onNext, onBack }) => {
    let history = useHistory();

    // const download = () => {
    //     return internalApi.formatUrl(`plan/download/${planId}`);
    //     // internalApi.get(`plan/download/${planId}`).then(res => {
    //     //     console.log('plan downloaded.');
    //     // });
    // }
    let downloadUrl = internalApi.apiUrl(`plan/download/${planId}?token=${storage.get('token')}`)

    const goHome = () => {
        history.push('/home');
    }

    return (
        <React.Fragment>
            <div className="text-center">
                <h1 className="h4 text-gray-900 mb-4">Download Your Plan</h1>
            </div>
            <div className="my-2"></div>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <ButtonSplit icon="fa-download" onClick={download}>Download</ButtonSplit>
            </div> */}
            <div className="my-2"></div>
            <div style={{ padding: '20px 0' }}>
                <form className="user">

                    <div className="form-group row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <a href={downloadUrl} target="_blank" rel="noopener noreferrer" className="btn btn-success btn-user btn-block"><i style={{marginRight:5}} className="fas fa-download"></i> Download</a>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <Button onClick={goHome} className="btn btn-primary btn-user btn-block">Go Home</Button>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>

                </form>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Download);