import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'react-fa';
import Image from '../../Image';
import { Button, ButtonLink, ButtonDelete } from '../../Form';
import Flex, { Col } from '../../Flex';

import _ from 'lodash';
import config from '../../../utils/config';
import storage from '../../../utils/storage';
import internalApi from '../../../utils/internalApi';


import cs from './component.module.scss';

class UploadInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    accept: PropTypes.string,
    onUpload: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    name: '',
    value: null,
    label: 'Click to Upload File ',
    accept: '*'
  };

  static isImage(type) {
    return (
      _.findIndex(['jpg', 'png', 'jpeg'], ext => type.includes(ext)) !== -1
    );
  }

  constructor(props) {
    super(props);

    let fileUrl = '';
    let isImage = false;
    if (props.value) {
      fileUrl = `${config('api.url')}resource/attachment/${
        props.value
        }?token=${storage.get('token')}`;
      isImage = UploadInput.isImage(props.value.split('.').pop());
    }

    this.state = {
      loading: false,
      fileUrl,
      isImage,
      file: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        fileUrl: `${config('api.url')}resource/attachment/${
          nextProps.value
          }?token=${storage.get('token')}&companyAlias=${storage.get(
            'companyAlias',
          )}`,
        isImage: UploadInput.isImage(nextProps.value.split('.').pop()),
      });
    }
  }

  onUploadImage = e => {
    e.persist();

    if (this.state.loading) {
      return;
    }

    const self = this;

    this.setState({ loading: true }, () => {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (() => fileEvt => {
        self.setState({
          fileUrl: fileEvt.target.result,
          loading: false,
          file,
          isImage: UploadInput.isImage(file.type),
        });
      })(file);

      reader.readAsDataURL(file);

      this.props.onUpload(this.props.name, file);
    });
  };

  deleteImage = () => {
    internalApi.post(`attachment/${this.props.value}/delete`).then(data => {
      if(typeof this.props.onDelete !== 'undefined'){
        this.setState({file:null,fileUrl:null},() => {
          this.props.onDelete(null);
        });
      }
    });
  }

  render() {
    const { file } = this.state;
    const fileName  = ((file && file.name) && file.name.split('.')[1] === 'csv') && file.name;
    let shouldDelete = typeof this.props.onDelete !== 'undefined';

    let { value = '' } = this.props;

    value = value?value.trim():null;
    
    return (
      <div className={cs.root}>
        {!value && (
          <Flex>
            <Col xs={{ size: 6, top: 20,display:'flex',textAlign:'center',alignItems:'center' }}>
              <label className={cs.label}>
                <input accept={this.props.accept} type="file" hidden onChange={this.onUploadImage} />
                <Button>
                  <Icon name="upload" />
                  <span className={cs.btnText}> {this.props.label}</span>
                </Button>
              </label>
               <span style={{color: '#000000',minWidth:100,marginTop:5}} >{fileName}</span>
            </Col>
          </Flex>
        )}
 
        {value &&
          this.state.isImage && (
            <React.Fragment>
              <Image style={{ marginTop: '10px' }} className={cs.img} src={this.state.fileUrl} />
              {shouldDelete && (
                <div style={{width:30}}>
                    <ButtonDelete onClick={this.deleteImage}><Icon name="trash" /> </ButtonDelete>
                </div>
              )}

            </React.Fragment>
          )}
        {value &&
          !this.state.isImage && (
            <Flex>
              <Col xs={{ size: 2, top: 20 }}>
                <ButtonLink
                  isExternal
                  fullWidth={false}
                  linkProps={{
                    href: this.state.fileUrl,
                    target: '_blank'
                  }}
                >
                  {file ? file.name : 'View Uploaded File' /*this.props.value*/}
                </ButtonLink>
              </Col>
              {shouldDelete && (
              <Col xs={{ size: 1, top: 20, left: 15 }}>
                <div style={{width:30}}>
                  <ButtonDelete onClick={this.deleteImage}><Icon name="trash" /></ButtonDelete>
                </div>
              </Col>
              )}
            </Flex>
          )}
      </div>
    );
  }
}

export default UploadInput;
