import React,{memo} from 'react';
//import styled from 'styled-components';
import { ButtonDelete } from './../../../../components/Form';
import { camelPad  } from './../../../../utils/common';


const TableDisplay = ({value = [],editItem,deleteItem}) => {
 
    let firstRow = (value && value[0])?value[0]:{};
    return (
    <div style={{width:'100%',overflowX:'auto'}}>
        <table className="table table-striped">
            <tbody>
                <tr>
                    {Object.keys(firstRow).map((key,index) => (
                        <th key={index}>{camelPad(key)}</th>
                    ))}
                    <th>Actions</th>
                </tr>
                
                
            {value && value.map((v, index) => (
                <tr key={index}>
                    {Object.keys(v).map((key,index) => (
                        <td key={index}>{v[key]}</td>
                    ))}
                    <td style={{width:100}}><i onClick={() => editItem(v,index)} style={{cursor:'pointer'}} className="fas fa-edit"></i> <ButtonDelete onClick={() => deleteItem(v,index)} transparent><i style={{cursor:'pointer',color:'red'}} className="fas fa-trash"></i></ButtonDelete></td>

                </tr>
            ))}
            </tbody>
        </table>
    </div>
    );

};

export default memo(TableDisplay);